import moment from "moment";
import { constants } from "@/utils/constants";
import { companyModule } from "@/store/modules/moduleCompany";
import { ResellerContactType, ResellerContact } from "@/api/reseller/ResellerModels";
import { ReadableDiscountGroups, ReadableMarketRegions } from "@/utils/bookingData";
import {
  EXT_DISNEY_BOOKING_BUFFER_DAYS,
  EXT_DISNEY_PARK_RESERVATION_STATUS,
  EXT_DISNEY_PARK_RESERVATIONS_REQUIRED,
  EXT_DISNEY_PRODUCT_DURATION,
} from "@/api/booking/Extensions";

export function calculateValidityRange(ext: any, startDate: string) {
  const rateBufferDays = Number(ext?.[EXT_DISNEY_BOOKING_BUFFER_DAYS]);
  const rateDuration = Number(ext?.[EXT_DISNEY_PRODUCT_DURATION]);
  //subtract 1 in calculation because startDate counts as 1
  const durationDate = moment(startDate, "YYYY-MM-DD")
    .add(rateBufferDays + rateDuration - 1, "days")
    .format("YYYY-MM-DD ");
  return {
    startDate: startDate,
    endDate: durationDate,
  };
}

export function reservationStatus(ext: any) {
  if (ext?.[EXT_DISNEY_PARK_RESERVATIONS_REQUIRED] === "true" && ext?.[EXT_DISNEY_PARK_RESERVATION_STATUS] === "none") {
    return "skipped";
  } else return ext?.[EXT_DISNEY_PARK_RESERVATION_STATUS] ? ext?.[EXT_DISNEY_PARK_RESERVATION_STATUS] : "none";
}

export function tablePaginationOptions(items: any[]) {
  return items.length && items.length <= 10 ? [] : [10, 25, 50, -1];
}

export function compareDateStrings(a: string, b: string): number {
  return moment(a).isBefore(moment(b)) ? -1 : 1;
}

export function arrayMove(array: any, oldIndex: number, newIndex: number) {
  const arr = [...array];
  while (oldIndex < 0) {
    oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

export function compareStrings(a: string, b: string): number {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function isNumber(n: any) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const knownRoles = Object.keys(constants.roles);

export const definitionRoles = Object.keys(constants.definitionRoles);

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getPickupInstruction(rateExt: any) {
  if (rateExt["beta-discountGroups"] === "FL_RESIDENT") {
    return "FloridaResident";
  } else if (rateExt["beta-productType"] === "specialevent") {
    return "SpecialEvent";
  } else if (rateExt["beta-marketRegions"] === "DOM_META" || rateExt["beta-marketRegions"] === "DOM_WHOLESALE") {
    return "Domestic";
  } else if (rateExt["beta-marketRegions"] !== "DOM_META" && rateExt["beta-marketRegions"] !== "DOM_WHOLESALE") {
    return "International";
  } else {
    return "WillCall";
  }
}

export function formatDate(d: Date): string {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  } as const;
  return d.toLocaleDateString("en-us", options);
}

export function formatDateAsMonthDayYear(d: Date): string {
  const options = { year: "numeric", month: "long", day: "numeric" } as const;
  return d.toLocaleDateString("en-us", options);
}

export function getNationalPhoneCode(countryCode: string) {
  const countriesData: any = require("@/assets/mocks/country_data.json");
  if (countriesData[countryCode] && countriesData[countryCode].phoneCode) {
    return countriesData[countryCode].phoneCode;
  }
  return "1";
}

export function handleKeyDownOnlyWholeNumeric(event: any) {
  const checkChars = ["e", "E", ".", "-", "+"];
  if (checkChars.includes(event.key)) {
    event.preventDefault();
  }
}

export function mapMarketRegions(marketRegions: any) {
  const tempMktRegions = marketRegions.map((mktRegion: string) => {
    const mktRegionName = getMarketRegionName(mktRegion);
    if (mktRegionName && mktRegionName.length > 0) {
      return {
        name: mktRegion,
        value: mktRegionName,
      };
    } else {
      return {
        name: mktRegion,
        value: mktRegion,
      };
    }
  });
  return tempMktRegions;
}

export function mapDiscountGroups(discountGroups: any) {
  const tempDiscountGroups = discountGroups.map((discountGroupCode: string) => {
    const discountName = getDiscountGroupName(discountGroupCode);
    if (discountName && discountName.length > 0) {
      return {
        name: discountGroupCode,
        value: discountName,
      };
    } else {
      return {
        name: discountGroupCode,
        value: discountGroupCode,
      };
    }
  });
  return tempDiscountGroups;
}

export function getMarketRegionName(mktRegion: string) {
  if (mktRegion && mktRegion.length > 0) {
    const marketRegionData = ReadableMarketRegions;
    let readableMktRegionName: any = {};
    if (mktRegion.indexOf(",") !== -1) {
      const arrMktRegions = mktRegion.split(",");
      let mktRegionName = "";
      arrMktRegions.forEach((strMktRegion) => {
        readableMktRegionName = marketRegionData.filter((tempData) => {
          return tempData.name === strMktRegion;
        });
        if (readableMktRegionName && readableMktRegionName.length > 0) {
          mktRegionName += readableMktRegionName[0]?.value + ", ";
        } else {
          mktRegionName += strMktRegion + ", ";
        }
      });
      const mktRegionLength = mktRegionName.length - 2;
      const tempMktRegionName = mktRegionName.substr(0, mktRegionLength);
      return tempMktRegionName;
    } else {
      const readableMktRegionName = marketRegionData.filter((tempData) => {
        return tempData.name === mktRegion;
      });
      if (readableMktRegionName && readableMktRegionName.length > 0) {
        return readableMktRegionName[0]?.value;
      } else {
        return mktRegion;
      }
    }
  } else {
    return "";
  }
}

export function getDiscountGroupName(discountGroupCode: string) {
  if (discountGroupCode && discountGroupCode.length > 0) {
    const discountGroupsData = ReadableDiscountGroups;
    if (discountGroupCode.indexOf(",") !== -1) {
      const arrDiscounts = discountGroupCode.split(",");
      let discountName = "";
      let readableDiscountName: any = {};
      arrDiscounts.forEach((strDiscount: string) => {
        readableDiscountName = discountGroupsData.filter((tempData) => {
          return tempData.name === strDiscount;
        });
        if (readableDiscountName && readableDiscountName.length > 0) {
          discountName += readableDiscountName[0]?.value + ", ";
        } else {
          discountName += strDiscount + ", ";
        }
      });
      const discountLength = discountName.length - 2;
      const tempDiscountName = discountName.substr(0, discountLength);
      return tempDiscountName;
    } else {
      const readableDiscountGroup = discountGroupsData.filter((tempData) => {
        return tempData.name === discountGroupCode;
      });
      if (readableDiscountGroup && readableDiscountGroup.length > 0) {
        return readableDiscountGroup[0]?.value;
      } else {
        return discountGroupCode;
      }
    }
  } else {
    return "";
  }
}

export function getErrorText(status: number): string {
  switch (status) {
    case 401:
      return "Unauthenticated";
    case 404:
      return "Not Found";
    case 409:
      return "Data out of date. Please refresh page.";
    case 500:
      return "Internal error. Try again later";
    default:
      // todo send message to backend
      return "Internal error";
  }
}

export function getCompanyContact(posId: string) {
  let phoneNumber = "";
  const companyContacts = companyModule.CompanyContacts;
  const posContacts = companyModule.PosContacts;
  const posContactDetails = posContacts.filter((posContact: ResellerContact) => {
    return posContact.reference === posId;
  });
  const resellerContact = companyModule.ResellerContacts;
  const agentContacts = companyContacts.filter((companyContact: ResellerContact) => {
    return companyContact.type === ResellerContactType.AGENT;
  });
  const distributorContacts = companyContacts.filter((companyContact: ResellerContact) => {
    return companyContact.type === ResellerContactType.DISTRIBUTOR;
  });
  if (agentContacts && agentContacts.length > 0) {
    phoneNumber = agentContacts[0].phone;
  } else if (posContacts && posContacts.length > 0 && posId && posContactDetails && posContactDetails.length > 0) {
    phoneNumber = posContactDetails[0].phone;
  } else if (distributorContacts && distributorContacts.length > 0) {
    phoneNumber = distributorContacts[0].phone;
  } else {
    phoneNumber = resellerContact[0].phone;
  }
  return phoneNumber;
}

export function openPrintPDF(pdfData: any) {
  if (pdfData) {
    const win: any = window.open();
    win.document.write(
      '<iframe src="' +
        pdfData +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
    );
  }
}

export function sortAlpha(data: any[], param: string) {
  return data?.sort((a: any, b: any) => (a[param] > b[param] ? 1 : b[param] > a[param] ? -1 : 0)) || [];
}

export function extractTimezoneFromRates(rates: any[]) {
  let timezone = moment.tz.guess();
  const rateWithTZ = rates.find((r: any) => r.hours?.[0].timezone);
  if (rateWithTZ) {
    timezone = rateWithTZ.hours[0].timezone;
  }
  return timezone;
}

export const timezones = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Valparaiso",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port_of_Spain",
  "America/Port-au-Prince",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/North",
  "Australia/NSW",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/East-Saskatchewan",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/UCT",
  "Etc/Universal",
  "Etc/UTC",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT0",
  "GMT-0",
  "Greenwich",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "Navajo",
  "NZ",
  "NZ-CHAT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "PRC",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "Universal",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/Eastern",
  "US/East-Indiana",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
  "UTC",
  "W-SU",
  "Zulu",
];
