import axios, { AxiosInstance } from "axios";
import { from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { config } from "@/utils/config";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { VoucherPDFRQ } from "./VoucherModels";
import { VoucherErrHandlers } from "./ErrHandlers";

const xsrfCookieName = "XSRF-TOKEN";
const voucherApi = "/get-pdf";
const voucherEmailApi = "/send-email";

export class VoucherClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.VoucherApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(VoucherClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    if (err.response?.status === 401) {
      return VoucherClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data;
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }
  private voucherDetails(req: any, errs?: VoucherErrHandlers): Observable<any> {
    return from(this.httpClient.post<any>(`${voucherApi}`, JSON.stringify(req))).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onVoucherNotFound && e.status === 404) {
            errs.onVoucherNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getVoucher(req: VoucherPDFRQ): Promise<any> {
    return this.voucherDetails(req).toPromise();
  }

  public async sendVoucherEmail(req: VoucherPDFRQ): Promise<any> {
    return from(this.httpClient.post<any>(`${voucherEmailApi}`, JSON.stringify(req)))
      .pipe(map((rs) => rs.data))
      .toPromise();
  }
}

const voucherClient = new VoucherClient();

export default voucherClient;
