/**
 * PointOfSale - represents the Disney point of sale location
 */
export class PointOfSale {
  code: string;
  name: string;
  channelID: string;
  bookingAPIKey: string;
  bookingAPISecret: string;
  property_brand: string;
  displayName: string;
}

export function setPoSDisplayName(pos: PointOfSale): void {
  pos.displayName = `${pos.code} - ${pos.name}`;
}
