import { codes } from "@/utils/codeConstants";
import { CodeAction } from "@/models";

/**
 * Event models for displaying popups to the user when events occur. User the following model to
 * feed into the event snackbars displayed to the user.
 *
 * Add a new event with the following model
 * Key: Use a code from the codeConstants.ts file
 * value:
 * - message (Required) - default string message displayed in the snackbar
 * - retryable (Optional) - if true, will display a "Retry" button
 * - viewable (Optional) - if true, will display a "View" button
 * - undoable (Optional) - if true, will display a "Undo" button
 * - hasHelp (Optional) - if true, will display a help Icon button
 * - helpID (Optional) - if hasHelp is set to true, you can be used to display an onscreen hint
 * - helpLink  (Optional) - if hasHelp is set to true, you can put an http string to launch an external help page.
 * Takes priority over helpID
 *
 */

const c = new Map<number, CodeAction>();

/**
 * Profile
 * Event codes 10000 - 10999
 */
c.set(codes.PROFILE_ERROR_UNAUTHORIZED, {
  message: "Unauthorized to view page",
});
/**
 * User
 * Event codes 11000 - 11999
 */
c.set(codes.USER_LOAD, {
  message: "Loaded user",
});
c.set(codes.USER_ACTIVATED, {
  message: "Activated user",
  viewable: true,
});
c.set(codes.USER_DEACTIVATED, {
  message: "Deactivated user",
  undoable: true,
});
c.set(codes.USER_UPDATED, {
  message: "User Updated",
});
c.set(codes.USER_DELETED, {
  message: "Deleted user",
});
c.set(codes.USER_CREATED, {
  message: "Created user",
  viewable: true,
});
c.set(codes.USER_ERROR_500_LOAD_USER, {
  message: "Unable to load user",
});
c.set(codes.USER_ERROR_500_CREATE_USER, {
  message: "Unable to create user",
});
c.set(codes.USER_ERROR_404_UPDATE_USER, {
  message: "Unable to update user",
});
c.set(codes.USER_ERROR_500_UPDATE_USER, {
  message: "Unable to update user",
});
c.set(codes.USER_ERROR_404_DELETE_USER, {
  message: "Unable to delete user",
});
c.set(codes.USER_ERROR_500_DELETE_USER, {
  message: "Unable to delete user",
});
c.set(codes.USER_ERROR_500_ACTIVATED_USER, {
  message: "Unable to activate user",
});
c.set(codes.USER_ERROR_500_DEACTIVATED_USER, {
  message: "Unable to deactivate user",
});

/*Ticket deactivated and activated */
c.set(codes.TICKET_DEACTIVATED, {
  message: "Deactivated ticket.",
});
c.set(codes.TICKET_ACTIVATED, {
  message: "Ticket activated.",
});
c.set(codes.TICKET_ERROR_500_DEACTIVATED_TICKET, {
  message: "Unable to deactivate ticket",
});
c.set(codes.TICKET_ERROR_404_DEACTIVATE_TICKET, {
  message: "Unable to deactivate ticket",
});
c.set(codes.TICKET_ERROR_400_DEACTIVATE_TICKET, {
  message: "Bad request",
});
/**
 * Company
 * Event codes 20000 - 20999
 */
c.set(codes.COMPANY_CONTACT_UPDATED, {
  message: "Company Contact Updated",
});
c.set(codes.COMPANY_CONTACT_DELETED, {
  message: "Company Contact Deleted",
});
c.set(codes.COMPANY_LOAD, {
  message: "Loaded company",
});
c.set(codes.COMPANY_CREATED, {
  message: "Added company",
});
c.set(codes.COMPANY_UPDATED, {
  message: "Updated company",
});
c.set(codes.COMPANY_DELETED, {
  message: "Removed company",
});
c.set(codes.COMPANY_LOAD_ADDRESS, {
  message: "Loaded addresses",
});
c.set(codes.COMPANY_LOAD_SUPPLIER_ADDRESS, {
  message: "Loaded addresses",
});
c.set(codes.COMPANY_LOAD_PRODUCT_ADDRESS, {
  message: "Loaded addresses",
});
c.set(codes.COMPANY_CREATE_COMPANY_ADDRESS, {
  message: "Added address",
});
c.set(codes.COMPANY_UPDATE_COMPANY_ADDRESS, {
  message: "Updated address",
});
c.set(codes.COMPANY_DELETE_COMPANY_ADDRESS, {
  message: "Removed address",
});
c.set(codes.COMPANY_CREATE_SUPPLIER_ADDRESS, {
  message: "Added address",
});
c.set(codes.COMPANY_CREATE_SUPPLIER_BULK_ADDRESS, {
  message: "Added addresses",
});
c.set(codes.COMPANY_UPDATE_SUPPLIER_ADDRESS, {
  message: "Updated address",
});
c.set(codes.COMPANY_DELETE_SUPPLIER_ADDRESS, {
  message: "Removed address",
});
c.set(codes.COMPANY_CREATE_PRODUCT_ADDRESS, {
  message: "Added address",
});
c.set(codes.COMPANY_UPDATE_PRODUCT_ADDRESS, {
  message: "Updated address",
});
c.set(codes.COMPANY_DELETE_PRODUCT_ADDRESS, {
  message: "Removed address",
});
c.set(codes.COMPANY_ERROR_404_LOAD_COMPANY, {
  message: "Unable to load company",
});
c.set(codes.COMPANY_ERROR_500_LOAD_COMPANY, {
  message: "Unable to load company",
  retryable: true,
});
c.set(codes.COMPANY_ERROR_500_CREATE_COMPANY, {
  message: "Unable to create",
});
c.set(codes.COMPANY_ERROR_404_UPDATE_COMPANY, {
  message: "Unable to update",
});
c.set(codes.COMPANY_ERROR_500_UPDATE_COMPANY, {
  message: "Unable to update",
});
c.set(codes.COMPANY_ERROR_404_DELETED_COMPANY, {
  message: "Unable to delete",
});
c.set(codes.COMPANY_ERROR_500_DELETED_COMPANY, {
  message: "Unable to delete",
});
c.set(codes.COMPANY_ERROR_404_LOAD_ADDRESS, {
  message: "Unable to load addresses",
});
c.set(codes.COMPANY_ERROR_500_LOAD_ADDRESS, {
  message: "Unable to load addresses",
});
c.set(codes.COMPANY_ERROR_404_LOAD_SUPPLIER_ADDRESS, {
  message: "Unable to load addresses",
});
c.set(codes.COMPANY_ERROR_500_LOAD_SUPPLIER_ADDRESS, {
  message: "Unable to load addresses",
});
c.set(codes.COMPANY_ERROR_404_LOAD_PRODUCT_ADDRESS, {
  message: "Unable to load addresses",
});
c.set(codes.COMPANY_ERROR_500_LOAD_PRODUCT_ADDRESS, {
  message: "Unable to load addresses",
});
c.set(codes.COMPANY_ERROR_404_CREATE_COMPANY_ADDRESS, {
  message: "Unable to create address",
});
c.set(codes.COMPANY_ERROR_500_CREATE_COMPANY_ADDRESS, {
  message: "Unable to create address",
});
c.set(codes.COMPANY_ERROR_404_UPDATE_COMPANY_ADDRESS, {
  message: "Unable to find address",
});
c.set(codes.COMPANY_ERROR_500_UPDATE_COMPANY_ADDRESS, {
  message: "Unable to update address",
});
c.set(codes.COMPANY_ERROR_404_DELETED_COMPANY_ADDRESS, {
  message: "Unable to find address",
});
c.set(codes.COMPANY_ERROR_500_DELETED_COMPANY_ADDRESS, {
  message: "Unable to delete address",
});
c.set(codes.COMPANY_ERROR_404_CREATE_SUPPLIER_ADDRESS, {
  message: "Unable to create address",
});
c.set(codes.COMPANY_ERROR_500_CREATE_SUPPLIER_ADDRESS, {
  message: "Unable to create address",
});
c.set(codes.COMPANY_ERROR_404_CREATE_SUPPLIER_BULK_ADDRESS, {
  message: "Unable to create addresses",
});
c.set(codes.COMPANY_ERROR_500_CREATE_SUPPLIER_BULK_ADDRESS, {
  message: "Unable to create addresses",
});
c.set(codes.COMPANY_ERROR_404_UPDATE_SUPPLIER_ADDRESS, {
  message: "Unable to find address",
});
c.set(codes.COMPANY_ERROR_500_UPDATE_SUPPLIER_ADDRESS, {
  message: "Unable to update address",
});
c.set(codes.COMPANY_ERROR_404_DELETED_SUPPLIER_ADDRESS, {
  message: "Unable to find address",
});
c.set(codes.COMPANY_ERROR_500_DELETED_SUPPLIER_ADDRESS, {
  message: "Unable to delete address",
});
c.set(codes.COMPANY_ERROR_404_CREATE_PRODUCT_ADDRESS, {
  message: "Unable to create address",
});
c.set(codes.COMPANY_ERROR_500_CREATE_PRODUCT_ADDRESS, {
  message: "Unable to create address",
});
c.set(codes.COMPANY_ERROR_404_UPDATE_PRODUCT_ADDRESS, {
  message: "Unable to find address",
});
c.set(codes.COMPANY_ERROR_500_UPDATE_PRODUCT_ADDRESS, {
  message: "Unable to update address",
});
c.set(codes.COMPANY_ERROR_404_DELETED_PRODUCT_ADDRESS, {
  message: "Unable to find address",
});
c.set(codes.COMPANY_ERROR_500_DELETED_PRODUCT_ADDRESS, {
  message: "Unable to delete address",
});
c.set(codes.COMPANY_ERROR_404_LOAD_CONTACTS, {
  message: "Unable to load contacts",
});
c.set(codes.COMPANY_ERROR_500_LOAD_CONTACTS, {
  message: "Unable to load contacts",
});
c.set(codes.COMPANY_ERROR_404_LOAD_USER_CONFIG, {
  message: "Unable to load user configuration",
});
c.set(codes.COMPANY_ERROR_404_LOAD_RESELLER, {
  message: "Unable to load reseller configuration",
});
/**
 * Product
 * Event codes 21000 - 21999
 */
c.set(codes.PRODUCT_LOAD, {
  message: "Loaded products",
});
c.set(codes.PRODUCT_CREATED, {
  message: "Created product",
});
c.set(codes.PRODUCT_UPDATED, {
  message: "Update product",
});
c.set(codes.PRODUCT_DELETED, {
  message: "Deleted product",
});
c.set(codes.PRODUCT_ERROR_500_LOAD_COMPANY, {
  message: "Unable to load product",
  retryable: true,
});
c.set(codes.PRODUCT_ERROR_500_CREATE_COMPANY, {
  message: "Unable to create company",
});
c.set(codes.PRODUCT_ERROR_404_UPDATE_COMPANY, {
  message: "Unable to update company",
});
c.set(codes.PRODUCT_ERROR_500_UPDATE_COMPANY, {
  message: "Unable to update company",
});
c.set(codes.PRODUCT_ERROR_404_DELETE_COMPANY, {
  message: "Unable to remove",
});
c.set(codes.PRODUCT_ERROR_500_DELETE_COMPANY, {
  message: "Unable to remove",
});
/**
 * Option
 * Event Codes 22000 - 22999
 */
c.set(codes.OPTION_LOAD, {
  message: "Loaded options",
});
c.set(codes.OPTION_CREATED, {
  message: "Created option",
});
c.set(codes.OPTION_UPDATED, {
  message: "Update option",
});
c.set(codes.OPTION_DELETED, {
  message: "Deleted option",
});
c.set(codes.OPTION_ERROR_500_LOAD_OPTION, {
  message: "Unable to load option",
  retryable: true,
});
c.set(codes.OPTION_ERROR_500_CREATE_OPTION, {
  message: "Unable to creat",
});
c.set(codes.OPTION_ERROR_404_UPDATE_OPTION, {
  message: "Unable to update",
});
c.set(codes.OPTION_ERROR_500_UPDATE_OPTION, {
  message: "Unable to update",
});
c.set(codes.OPTION_ERROR_404_DELETE_OPTION, {
  message: "Unable to remove",
});
c.set(codes.OPTION_ERROR_500_DELETE_OPTION, {
  message: "Unable to remove",
});
/**
 * Traveler Type
 * Event codes 23000 - 23999
 */
c.set(codes.TRAVELER_TYPE_LOAD, {
  message: "Loaded traveler types",
});
c.set(codes.TRAVELER_TYPE_CREATED, {
  message: "Added traveler types",
});
c.set(codes.TRAVELER_TYPE_UPDATED, {
  message: "Updated traveler types",
});
c.set(codes.TRAVELER_TYPE_DELETED, {
  message: "Removed traveler types",
});
c.set(codes.TRAVELER_TYPE_ERROR_500_LOAD, {
  message: "Unable to load",
  retryable: true,
});
c.set(codes.TRAVELER_TYPE_ERROR_500_CREATE_TRAVELER_TYPE, {
  message: "Unable to create",
});
c.set(codes.TRAVELER_TYPE_ERROR_500_UPDATE_TRAVELER_TYPE, {
  message: "Unable to update",
});
c.set(codes.TRAVELER_TYPE_ERROR_404_UPDATE_TRAVELER_TYPE, {
  message: "Unable to update",
});
c.set(codes.TRAVELER_TYPE_ERROR_404_DELETE_TRAVELER_TYPE, {
  message: "Unable to remove",
});
c.set(codes.TRAVELER_TYPE_ERROR_500_DELETE_TRAVELER_TYPE, {
  message: "Unable to remove",
});
c.set(codes.TRAVELER_TYPE_ERROR_MALFORMED_DELETE_TRAVELER_TYPE, {
  message: "Unable to remove",
});
/**
 * Price schedule
 * Event codes 24000 - 24999
 */
c.set(codes.PRICE_SCHEDULE_LOAD, {
  message: "Loaded price schedules",
});
c.set(codes.PRICE_SCHEDULE_CREATED, {
  message: "Added price schedule",
  // viewable: true,
});
c.set(codes.PRICE_SCHEDULE_UPDATED, {
  message: "Updated price schedule",
  // viewable: true,
  // undoable: true,
});
c.set(codes.PRICE_SCHEDULE_DELETED, {
  message: "Removed price schedule",
  // undoable: true,
});
c.set(codes.PRICE_SCHEDULE_ERROR_500_LOAD_PRICES, {
  message: "Unable to load prices",
  retryable: true,
});
c.set(codes.PRICE_SCHEDULE_ERROR_500_CREATE_PRICE_SCHEDULE, {
  message: "Unable to create price schedule",
});
c.set(codes.PRICE_SCHEDULE_ERROR_404_UPDATE_PRICE_SCHEDULE, {
  message: "Unable to update price schedule",
});
c.set(codes.PRICE_SCHEDULE_ERROR_500_UPDATE_PRICE_SCHEDULE, {
  message: "Unable to update price schedule",
});
c.set(codes.PRICE_SCHEDULE_ERROR_404_REMOVE_PRICE_SCHEDULE, {
  message: "Unable to remove price schedule",
});
c.set(codes.PRICE_SCHEDULE_ERROR_500_REMOVE_PRICE_SCHEDULE, {
  message: "Unable to remove price schedule",
});
c.set(codes.PRICE_AND_TAXES_LOAD, {
  message: "Loaded price",
});
c.set(codes.PRICE_AND_TAXES_CREATED, {
  message: "Added price",
  // viewable: true,
});
c.set(codes.PRICE_AND_TAXES_UPDATED, {
  message: "Updated price",
  // viewable: true,
  // undoable: true,
});
c.set(codes.PRICE_AND_TAXES_DELETED, {
  message: "Removed price",
  // undoable: true,
});
c.set(codes.PRICE_AND_TAXES_ERROR_500_LOAD_PRICES, {
  message: "Unable to load prices",
  retryable: true,
});
c.set(codes.PRICE_AND_TAXES_ERROR_500_CREATE_PRICE, {
  message: "Unable to create price",
  // retryable: true,
});
c.set(codes.PRICE_AND_TAXES_ERROR_404_UPDATE_PRICE, {
  message: "Unable to update price",
});
c.set(codes.PRICE_AND_TAXES_ERROR_500_UPDATE_PRICE, {
  message: "Unable to update price",
  // retryable: true,
});
c.set(codes.PRICE_AND_TAXES_ERROR_404_REMOVE_PRICE, {
  message: "Unable to remove price",
});
c.set(codes.PRICE_AND_TAXES_ERROR_500_REMOVE_PRICE, {
  message: "Unable to remove price",
  // retryable: true,
});
c.set(codes.PRICE_LOAD, {
  message: "Loaded price",
});
c.set(codes.PRICE_CREATED, {
  message: "Added price",
  // viewable: true,
});
c.set(codes.PRICE_UPDATED, {
  message: "Updated price",
  // viewable: true,
  // undoable: true,
});
c.set(codes.PRICE_DELETED, {
  message: "Removed price",
  // undoable: true,
});
c.set(codes.PRICE_ERROR_500_LOAD_PRICES, {
  message: "Unable to load prices",
  retryable: true,
});
c.set(codes.PRICE_ERROR_500_CREATE_PRICE, {
  message: "Unable to create price",
  // retryable: true,
});
c.set(codes.PRICE_ERROR_404_UPDATE_PRICE, {
  message: "Unable to update price",
});
c.set(codes.PRICE_ERROR_500_UPDATE_PRICE, {
  message: "Unable to update price",
  // retryable: true,
});
c.set(codes.PRICE_ERROR_404_REMOVE_PRICE, {
  message: "Unable to remove price",
});
c.set(codes.PRICE_ERROR_500_REMOVE_PRICE, {
  message: "Unable to remove price",
  // retryable: true,
});
/**
 * Taxes and Fees
 * Event codes 25000 - 25999
 */
c.set(codes.TAXES_AND_FEES_LOAD, {
  message: "Loaded taxes and fees",
});
c.set(codes.TAXES_AND_FEES_CREATED, {
  message: "Added taxes and fees",
});
c.set(codes.TAXES_AND_FEES_UPDATED, {
  message: "Updated taxes and fees",
});
c.set(codes.TAXES_AND_FEES_DELETED, {
  message: "Removed taxes and fees",
});
c.set(codes.TAXES_AND_FEES_ERROR_500_LOAD_TAXES, {
  message: "Unable to load taxes and fees",
  retryable: true,
});
c.set(codes.TAXES_AND_FEES_ERROR_500_CREATE_TAXES, {
  message: "Unable to create",
});
c.set(codes.TAXES_AND_FEES_ERROR_404_UPDATE_TAXES, {
  message: "Unable to update",
});
c.set(codes.TAXES_AND_FEES_ERROR_500_UPDATE_TAXES, {
  message: "Unable to update",
});
c.set(codes.TAXES_AND_FEES_ERROR_404_DELETE_TAXES, {
  message: "Unable to remove",
});
c.set(codes.TAXES_AND_FEES_ERROR_500_DELETE_TAXES, {
  message: "Unable to remove",
});
c.set(codes.TAXES_AND_FEES_FEEDBACK_SENT, {
  message: "Message was sent",
});

/**
 * Bookings
 * Event codes 33000 - 33999
 */
c.set(codes.POS_ERROR_LOAD, {
  message: "POS not available",
});
c.set(codes.POS_ERROR_404_UPDATE_POS, {
  message: "Unable to update user's pos",
});
c.set(codes.POS_ERROR_500_UPDATE_POS, {
  message: "Unable to update user's pos",
});

c.set(codes.POS_ERROR_DETAILS, {
  message: "POS Details Error",
});
c.set(codes.POS_NOT_FOUND, {
  message: "POS distributor not found",
});
c.set(codes.SUPPLIERS_ERROR_LOAD, {
  message: "Suppliers Error",
});
c.set(codes.PRODUCTS_ERROR_LOAD, {
  message: "Products Error",
});
c.set(codes.RATES_ERROR_LOAD, {
  message: "Rates Error",
});
c.set(codes.PRICESCHEDULE_ERROR_LOAD, {
  message: "Price-Schedule Error",
});
c.set(codes.AVAILABILITY_ERROR, {
  message: "Availability Error",
});
c.set(codes.ACQUIRE_HOLD_ERROR, {
  message: "Acquire Hold Error",
});
c.set(codes.CREATE_BOOKING_ERROR, {
  message: "Create Booking Error",
});
c.set(codes.CREATE_BOOKING_SUCCESS, {
  message: "Booking Created Successfully",
});
c.set(codes.CANCEL_BOOKING_SUCCESS, {
  message: "Booking Cancelled Successfully",
});
c.set(codes.BOOKING_CONFIRMATION_SUCCESS, {
  message: "Booking Confirmation Sent Successfully",
});
c.set(codes.BOOKING_CONFIRMATION_ERROR, {
  message: "Booking Confirmation Sent Error",
});
c.set(codes.DISNEY_CONFIRMATION_SUCCESS, {
  message: "Disney Confirmation Sent Successfully",
});
c.set(codes.DISNEY_CONFIRMATION_ERROR, {
  message: "Disney Confirmation Sent Error",
});

c.set(codes.SUPPLIERS_ERROR_RQ, {
  message: "Suppliers Request Error",
});
c.set(codes.PRODUCTS_ERROR_RQ, {
  message: "Products Request Error",
});
c.set(codes.RATES_ERROR_RQ, {
  message: "Rates Request Error",
});
c.set(codes.PRICESCHEDULE_ERROR_RQ, {
  message: "Price-Schedule Request Error",
});
c.set(codes.AVAILABILITY_ERROR_RQ, {
  message: "Availability Request Error",
});
c.set(codes.CART_EMPTY_ERROR, {
  message: "No items in cart to checkout",
});
c.set(codes.ACQUIRE_HOLD_ERROR_RQ, {
  message: "Acquire Hold Request Error",
});
c.set(codes.CREATE_BOOKING_ERROR_RQ, {
  message: "Create Booking Request Error",
});
c.set(codes.BOOKING_NOT_FOUND_ERROR, {
  message: "Booking Id not found.",
});
/**
 * PRICING
 * Event codes 55000 - 55999
 */
c.set(codes.CREATE_PRICING_SUCCESS, {
  message: "Pricing Created Successfully",
});

c.set(codes.CREATE_PRICING_ERROR, {
  message: "Create Pricing Error",
});

c.set(codes.GET_PRICING_SUCCESS, {
  message: "Get Pricing Successfully",
});

c.set(codes.GET_PRICING_ERROR, {
  message: "Get Pricing Error",
});

c.set(codes.UPDATE_PRICING_SUCCESS, {
  message: "Pricing Updated Successfully",
});

c.set(codes.UPDATE_PRICING_ERROR, {
  message: "Pricing Update Error",
});

c.set(codes.DELETE_PRICING_SUCCESS, {
  message: "Pricing Deleted Successfully",
});

c.set(codes.DELETE_PRICING_ERROR, {
  message: "Pricing Delete Error",
});

c.set(codes.CREATE_PRICING_SAMEDATE, {
  message: "Create Pricing of Same Dates",
});

c.set(codes.CREATE_PRICING_OVERLAP, {
  message: "Pricing Dates Overlaps",
});

c.set(codes.ORIGINAL_AMOUNT_ERROR, {
  message: "Original Amount not available.",
});

c.set(codes.NET_AMOUNT_ERROR, {
  message: "Net Amount not available.",
});

c.set(codes.RETAIL_AMOUNT_ERROR, {
  message: "Retail Amount not available.",
});

c.set(codes.SELECT_DATES_RANGE, {
  message: "Select Date Range",
});

c.set(codes.SELECT_START_DATE, {
  message: "Select Start Date",
});

c.set(codes.SELECT_END_DATE, {
  message: "Select End Date",
});

c.set(codes.END_DATE_ERROR, {
  message: "Select End Date after the Start Date",
});

c.set(codes.SELECT_ADJUSTMENT_TYPE, {
  message: "Select Adjustment Type",
});

c.set(codes.SELECT_ADJUSTMENT_VAL, {
  message: "Select Adjustment Value",
});

c.set(codes.SELECT_SUPPLIER_ID, {
  message: "Select Supplier ID",
});

c.set(codes.SELECT_CHANNEL_ID, {
  message: "Select Channel ID",
});

c.set(codes.SELECT_PRODUCT_ID, {
  message: "Select Product ID",
});

c.set(codes.SELECT_TICKET_ID, {
  message: "Select Ticket ID",
});

/**
 * Channels
 * Event codes 28000 - 28999
 */
c.set(codes.CHANNELS_FEEDBACK_SENT, {
  message: "Message was sent",
});
/**
 * Reservations
 * Event codes 30000 - 30999
 */
c.set(codes.RESERVATIONS_LOAD_ERROR, {
  message: "Failed to load reservations",
});
/**
 * Generic errors
 * Event codes 90000 - 99999
 */
c.set(codes.GENERAL_MESSAGE_NO_ORG_ID, {
  message: `Error ${codes.GENERAL_MESSAGE_NO_ORG_ID}: No Company ID, request not allowed`,
});
c.set(codes.GENERAL_MESSAGE_NO_SUPPLIER_ID, {
  message: `Error ${codes.GENERAL_MESSAGE_NO_SUPPLIER_ID}: No Supplier ID, request not allowed`,
});
/**
 * Reports
 * Event codes 29000 - 29999
 */
c.set(codes.REPORTS_ERROR_400_SEARCH, {
  message: "No data found",
});
c.set(codes.REPORTS_ERROR_500_SEARCH, {
  message: "Error loading reporting data",
});

export const codeConfiguration = c;
