import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { BookingsReport } from "@/api/reports/ReportsModels";

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-reports",
})
class ModuleReports extends VuexModule {
  private loading = false;

  private reports: BookingsReport = {
    totalBookings: 0,
    totalRedemptions: 0,
    totalNetSales: 0,
    totalGrossSales: 0,
    items: [],
  };

  get Loading(): boolean {
    return this.loading;
  }

  get Reports(): BookingsReport {
    return this.reports;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setReports(reports: BookingsReport) {
    this.reports = reports;
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
  }

  @Action
  setReportData(reports: BookingsReport) {
    this.setReports(reports);
  }
}

export const reportsModule = getModule(ModuleReports, store);
