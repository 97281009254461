/**
 * getBookingAPIErrorMessage - attempts to extract the error details from a Booking API error response
 * @param response
 */
export function getBookingAPIErrorMessage(response: any): string {
  if (response && response.error && response.error.message) {
    return response.error.message;
  }
  return JSON.stringify(response);
}

/**
 * getBookingAPIPartnerErrorMessage - attempts to extract the partner error details from a Booking API error response
 * @param response
 */
export function getBookingAPIPartnerErrorMessage(response: any): string {
  const errMsg = getBookingAPIErrorMessage(response);
  if (response && response.error && response.error.data) {
    const partnerName = response.error.data["PARTNER_NAME"] || "unknown";
    const partnerError = response.error.data["PARTNER_ERROR"] || errMsg;
    return `Partner Error; ${partnerName} - ${partnerError}`;
  }
  return `Partner Error; ${errMsg}`;
}

/**
 * isBookingAPIErrorMaintenanceWindow - checks to see if the error was due to a maintenance window
 * @param response
 */
export function isBookingAPIErrorMaintenanceWindow(response: any): boolean {
  if (
    response &&
    response.error &&
    response.error.data &&
    response.error.data["PARTNER_ERROR_DETAILS"] &&
    response.error.data["PARTNER_ERROR_DETAILS"].indexOf("[code:196]") > -1
  ) {
    return true;
  }
  return false;
}
