/**
 * LogLevel - the different logging levels
 */
export enum LogLevel {
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

/**
 * LogRecord - contains basic log information
 */
export interface LogRecord {
  severity: string;
  timestamp: string;
  message: string;
  metadata?: Record<string, any>;
}
