import axios, { AxiosInstance } from "axios";
import { from, Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import Vue from "vue";
import { config } from "@/utils/config";
import {
  ListUsersRS,
  UpdateUserRQ,
  ApiLoginRQ,
  ApiLoginRS,
  QuerySearchTicket,
  ResponseGetTicketsResponse,
  RequestPostValidate,
  ResponsePostValidate,
  OrganizationWrapper,
} from "@/api/backplane/BackplaneModels";
import { QueryForgotPassword, QueryListUsers } from "@/api/backplane/BackplaneModels";
import {
  ErrorResponse as BackplaneApiError,
  Account,
  ResponseGetUsers,
  ResponsePutUser,
  ResponsePostUser,
  RequestChangePassword,
  RequestResetPassword,
  ResponseGetGroups,
  RequestPutUserGroups,
} from "@/api/backplane/BackplaneModels";
import {
  ActivateUserErrHandlers,
  ChangePasswordErrHandlers,
  CreateUserErrHandlers,
  DeactivateUserErrHandlers,
  DeleteUserErrHandlers,
  ForgotPasswordErrHandlers,
  GetUserErrHandlers,
  ListUsersErrHandlers,
  LoginErrHandlers,
  MeErrHandlers,
  ResetPasswordErrHandlers,
  UpdateUserErrHandlers,
  GetRolesErrHandlers,
  UpdateUserRoleErrHandlers,
  SearchTicketsErrHandlers,
  MakeValidationErrHandlers,
} from "@/api/userErrHandlers/ErrHandlers";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";

const xsrfCookieName = "XSRF-TOKEN";
const versionBackplane = "/backplane/v1";

export class BackplaneClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.BackplaneApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(BackplaneClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    // if (err.response?.status >= 400) {
    //   console.clear();
    // }

    if (err.response?.status === 401) {
      return BackplaneClient.on401(err);
    }

    // backplane err
    if (err.config.url?.includes(versionBackplane) && err.response.data && err.response.data.code) {
      const backplaneErr = err.response.data as BackplaneApiError;
      return Promise.reject({
        status: err.response.status,
        backplaneErr,
        ...err,
      });
    }
    // backplane err with some unknown payload or not 4xx wps err
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  /* configure client */

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    BackplaneClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }

  /* login/logout */

  public login(rq: ApiLoginRQ, errs?: LoginErrHandlers): Observable<ApiLoginRS> {
    return from(this.httpClient.post<ApiLoginRS>("/login", rq)).pipe(
      map((rs) => rs.data),
      tap((rs) => {
        Vue.$cookies.set(xsrfCookieName, rs.xcsrftoken, "1y");
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onEmailNotFound && e.status === 404) {
            errs.onEmailNotFound(e);
          } else if (errs.onEmptyEmail && e.status === 400 && e.wpsErr?.code === 1056) {
            errs.onEmptyEmail(e);
          } else if (errs.onEmptyPassword && e.status === 400 && e.wpsErr?.code === 1057) {
            errs.onEmptyPassword(e);
          }
        }, errs),
      ),
    );
  }

  public logout(): Observable<null> {
    return from(this.httpClient.get("/logout")).pipe(map(() => null));
  }

  /* backplane password */
  public forgotPassword(query: QueryForgotPassword, errs?: ForgotPasswordErrHandlers): Observable<null> {
    return from(this.httpClient.post(`/forgot-password`, query)).pipe(
      map(() => null),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onEmptyEmail && e.status === 400) {
            errs.onEmptyEmail(e);
          }
        }, errs),
      ),
    );
  }
  public resetPassword(rq: RequestResetPassword, errs?: ResetPasswordErrHandlers): Observable<null> {
    return from(this.httpClient.put(`/reset-password`, rq)).pipe(
      map(() => null),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onEmptyPasswordOrQueryParam && e.status === 400) {
            errs.onEmptyPasswordOrQueryParam(e);
          }
        }, errs),
      ),
    );
  }

  public changePassword(rq: RequestChangePassword, errs?: ChangePasswordErrHandlers): Observable<null> {
    return from(this.httpClient.put(`/change-password`, rq)).pipe(
      map(() => null),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onEmptyPassword && e.status === 400) {
            errs.onEmptyPassword(e);
          }
          throw e;
        }, errs),
      ),
    );
  }

  /* backplane users */
  public me(errs?: MeErrHandlers): Observable<Account> {
    return from(this.httpClient.get<Account>(`/me`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public listUsers(query: QueryListUsers, errs?: ListUsersErrHandlers): Observable<ListUsersRS> {
    return from(
      this.httpClient.get<ListUsersRS>(`/users`, {
        params: query,
      }),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getUser(userId: string, errs?: GetUserErrHandlers): Observable<ResponseGetUsers> {
    return from(this.httpClient.get<ResponseGetUsers>(`/users/${userId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateUser(userId: string, rq: UpdateUserRQ, errs?: UpdateUserErrHandlers): Observable<ResponsePutUser> {
    const {
      account: { id, username, org_code, email, given_name, phone, surname, department, impersonated_id },
      wps_data,
    } = rq;
    const modUser = {
      account: { id, username, org_code, email, given_name, phone, surname, department, impersonated_id },
      wps_data,
    };
    return from(this.httpClient.put<ResponsePutUser>(`/user`, modUser)).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e) => {
          throw e;
        }, errs),
      ),
    );
  }

  public updateUserRole(userId: string, rq: RequestPutUserGroups, errs?: UpdateUserRoleErrHandlers): Observable<ResponsePutUser> {
    return from(
      this.httpClient.put<ResponsePutUser>(`/users/groups`, rq, {
        params: {
          userId: userId,
        },
      }),
    ).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onUserNotFound && e.status === 404) {
            errs.onUserNotFound(e);
          }
          throw e;
        }, errs),
      ),
    );
  }

  public createUser(rq: UpdateUserRQ, errs?: CreateUserErrHandlers): Observable<ResponsePostUser> {
    return from(this.httpClient.post<ResponsePostUser>(`/user`, rq)).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onUserNotFound && e.status === 404) {
            errs.onUserNotFound(e);
          } else if (errs.onNoOrgCode && e.status === 400 && e.wpsErr?.code === 1130) {
            errs.onNoOrgCode(e);
          } else if (errs.onWrongExt && e.status === 400 && e.wpsErr?.code === 1131) {
            errs.onWrongExt(e);
          }
        }, errs),
      ),
    );
  }

  public deleteUser(userId: string, errs?: DeleteUserErrHandlers): Observable<null> {
    return from(this.httpClient.delete(`/users/${userId}`)).pipe(
      map(() => null),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onUserNotFound && e.status === 404) {
            errs.onUserNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public deactivateUser(userId: string, errs?: DeactivateUserErrHandlers): Observable<null> {
    return from(this.httpClient.put(`/users/${userId}/deactivate`)).pipe(
      map(() => null),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onUserNotFound && e.status === 404) {
            errs.onUserNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public activateUser(userId: string, errs?: ActivateUserErrHandlers): Observable<null> {
    return from(this.httpClient.put(`/users/${userId}/activate`)).pipe(
      map(() => null),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onUserNotFound && e.status === 404) {
            errs.onUserNotFound(e);
          }
        }, errs),
      ),
    );
  }

  /* Backplane Roles */
  public getRoles(errs?: GetRolesErrHandlers): Observable<ResponseGetGroups> {
    return from(this.httpClient.get<ResponseGetGroups>(`/groups`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public searchTickets(query: QuerySearchTicket, errs?: SearchTicketsErrHandlers): Observable<ResponseGetTicketsResponse> {
    return from(
      this.httpClient.get<ResponseGetTicketsResponse>(`/tickets/search`, {
        params: query,
      }),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOrganizationByCode(errs?: ListUsersErrHandlers): Observable<OrganizationWrapper> {
    return from(this.httpClient.get<OrganizationWrapper>(`/organizations`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public makeValidation(rq: RequestPostValidate, errs?: MakeValidationErrHandlers): Observable<ResponsePostValidate> {
    return from(this.httpClient.post<ResponsePostValidate>(`/validations`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
}

const backplaneClient = new BackplaneClient();
export default backplaneClient;
