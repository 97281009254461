import Vue from "vue";
import App from "./App.vue";
import VueCookies from "vue-cookies";
import router from "./router";
import i18n from "@/i18n";
import store from "./store";
import "./styles/styles.scss";
import RdmComponents from "@redeam/vue-common-components";
import { VuetifyPlugin } from "@/plugins";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@redeam/vue-common-components/dist/vue-common-components.css";
import "@mdi/font/css/materialdesignicons.css";
import { FromSmallestUnit } from "@/utils/currency";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import VueGtag from "vue-gtag";
import { config } from "@/utils/config";
import filters from "@/filters";
import VTooltip from "v-tooltip";
const moment = require("moment");

Vue.config.productionTip = false;

Vue.use(RdmComponents);
Vue.use(VueCookies);
Vue.use(VTooltip);
Vue.use(ElementUI, { locale });
/** Google Analytics **/
Vue.use(
  VueGtag,
  {
    appName: "Reseller Portal",
    config: { id: config.GoogleAnalyticsTagId },
    pageTrackerScreenviewEnabled: true,
    enabled: !config.IsLocalEnv,
  },
  router,
);
Vue.filter("formatDate", function (value: string) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD");
  }
});
Vue.filter("formatCurrencyValue", function (amount: any, currency: string) {
  const amtValue: number = parseInt(amount);
  if (isNaN(amtValue) || !currency) {
    return null;
  }
  return FromSmallestUnit(amtValue, currency);
});

filters.forEach((filter) => Vue.filter(filter.filterName, filter.filter));

new Vue({
  router,
  store,
  i18n,
  vuetify: VuetifyPlugin,
  render: (h) => h(App),
}).$mount("#app");
