import loggingClient from "@/api/logging/logging.client";
import { LogRecord, LogLevel } from "@/api/logging/logging.models";

/**
 * Logger - logging class for the UI
 *
 * Example Usage:
 *   const logger = new Logger();
 *   logger.info('This is an information message.', { key: 'value' });
 *   logger.warn('This is a warning message.');
 *   logger.error('This is an error message.');
 */
export class Logger {
  constructor() {}

  private constructLogRecord(level: LogLevel, message: string, metadata?: Record<string, any>): LogRecord {
    const logRecord: LogRecord = {
      severity: level,
      timestamp: new Date().toISOString(),
      message: message,
    };
    if (metadata) {
      logRecord.metadata = metadata;
    }
    return logRecord;
  }

  private log(level: LogLevel, message: string, metadata?: Record<string, any>): void {
    const logRecord = this.constructLogRecord(level, message, metadata);
    loggingClient.log(logRecord);
  }

  console(message: string, metadata?: Record<string, any>): void {
    const logRecord = this.constructLogRecord(LogLevel.DEBUG, message, metadata);
    console.log(JSON.stringify(logRecord));
  }

  debug(message: string, metadata?: Record<string, any>): void {
    this.log(LogLevel.DEBUG, message, metadata);
  }

  info(message: string, metadata?: Record<string, any>): void {
    this.log(LogLevel.INFO, message, metadata);
  }

  warn(message: string, metadata?: Record<string, any>): void {
    this.log(LogLevel.WARN, message, metadata);
  }

  error(message: string, metadata?: Record<string, any>): void {
    this.log(LogLevel.ERROR, message, metadata);
  }
}
