export * from "./RegistrationModel";
// export * from './TravelerType';
// export * from './Operator';
// export * from './ApiV2Models';
export * from "./UIModels";

export interface ILanguage {
  name: string;
  code: string;
}

export class DateRangeModel {
  start: string;

  end: string;

  constructor(start: string, end: string) {
    this.start = start;
    this.end = end;
  }
}

export class GenericAddress {
  streetAddress1: string;

  streetAddress2: string;

  city: string;

  province: string;

  postalCode: string;

  country: string;

  constructor() {
    this.streetAddress1 = "";
    this.streetAddress2 = "";
    this.city = "";
    this.province = "";
    this.postalCode = "";
    this.country = "";
  }
}
