import axios, { AxiosInstance } from "axios";
import { from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { config } from "@/utils/config";
import { intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { AcquireHoldRQ, AcquireHoldRS } from "./AcquireHoldModels";
import { BookingAPIAxiosErrorInterceptor, HoldRSErrHandlers } from "./ErrHandlers";

const xsrfCookieName = "XSRF-TOKEN";
const holdApi = "/acquire-hold";
const holdApiRelease = "/release-hold";

export class HoldClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.BookingApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(BookingAPIAxiosErrorInterceptor));
  }

  /* Acquire hold */
  private acquireHold(req: AcquireHoldRQ, errs?: HoldRSErrHandlers): Observable<AcquireHoldRS> {
    return from(this.httpClient.post<any>(`${holdApi}`, JSON.stringify(req))).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onHoldNotFound && e.status === 404) {
            errs.onHoldNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async acquireHoldRS(req: AcquireHoldRQ): Promise<AcquireHoldRS> {
    return this.acquireHold(req)
      .toPromise()
      .then((holdRS) => {
        return holdRS;
      });
  }

  private releaseHold(id: string, errs?: HoldRSErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`${holdApiRelease}` + "/" + id)).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onHoldNotFound && e.status === 404) {
            errs.onHoldNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async releaseHoldRS(id: string): Promise<any> {
    const holdRS = await this.releaseHold(id).toPromise();
    return holdRS;
  }
}

const holdClient = new HoldClient();

export default holdClient;
