import { colors } from "./colors";

export const RdmVuetifyConfig: any = {
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: {
          base: colors.primaryBase,
          lighten1: colors.primaryLighten1,
          lighten2: colors.primaryLighten2,
          lighten3: colors.primaryLighten3,
          lighten4: colors.primaryLighten4,
          lighten5: colors.primaryLighten5,
          darken1: colors.primaryDarken1,
          darken2: colors.primaryDarken2,
          darken3: colors.primaryDarken3,
          darken4: colors.primaryDarken4,
        },
        secondary: {
          base: colors.secondaryBase,
          lighten1: colors.secondaryLighten1,
          lighten2: colors.secondaryLighten2,
          lighten3: colors.secondaryLighten3,
          lighten4: colors.secondaryLighten4,
          lighten5: colors.secondaryLighten5,
          darken1: colors.secondaryDarken1,
          darken2: colors.secondaryDarken2,
          darken3: colors.secondaryDarken3,
          darken4: colors.secondaryDarken4,
        },
        accent: colors.accent,
        accentSecondary: colors.accentSecondary,
        error: colors.error,
        warning: colors.warning,
        success: colors.success,
        info: colors.info,
        text: colors.text,

        outline: {
          base: colors.outlineBase,
          lighten1: colors.outlineLighten1,
          lighten2: colors.outlineLighten2,
          lighten3: colors.outlineLighten3,
          lighten4: colors.outlineLighten4,
          lighten5: colors.outlineLighten5,
          darken1: colors.outlineDarken1,
          darken2: colors.outlineDarken2,
          darken3: colors.outlineDarken3,
          darken4: colors.outlineDarken4,
        },

        gray: {
          base: colors.grayBase,
          lighten1: colors.grayLighten1,
          lighten2: colors.grayLighten2,
          lighten3: colors.grayLighten3,
          lighten4: colors.grayLighten4,
          lighten5: colors.grayLighten5,
          darken1: colors.grayDarken1,
          darken2: colors.grayDarken2,
          darken3: colors.grayDarken3,
          darken4: colors.grayDarken4,
        },
      },
    },
  },
  icons: {
    iconfont: "md",
  },
};
