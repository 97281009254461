export const colors: any = {
  primary: "#29357A",
  secondary: "#68BFC8",
  outline: "#D6D5E3",
  text: "#101010",
  accent: "#F88075",
  accentSecondary: "#EB3860",
  error: "#D32F2F",
  warning: "#BF5900",
  success: "#2E7B32",
  info: "#2196F3",

  primaryLighten5: "#5a6bc8",
  primaryLighten4: "#475ac1",
  primaryLighten3: "#3c4eb3",
  primaryLighten2: "#3646a0",
  primaryLighten1: "#2f3d8d",
  primaryLight: "#5a5eaa",
  primaryBase: "#29357A",
  primaryDark: "#00114d",
  primaryDarken1: "#232D67",
  primaryDarken2: "#1c2454",
  primaryDarken3: "#161c41",
  primaryDarken4: "#10142e",

  secondaryLighten5: "#c5e7ea",
  secondaryLighten4: "#b3dfe3",
  secondaryLighten3: "#a0d7dc",
  secondaryLighten2: "#8dcfd6",
  secondaryLighten1: "#7bc7cf",
  secondaryLight: "#9bf2fb",
  secondaryBase: "#68BFC8",
  secondaryDark: "#328f97",
  secondaryDarken1: "#55b7c1",
  secondaryDarken2: "#44aeb9",
  secondaryDarken3: "#3d9da7",
  secondaryDarken4: "#368b94",

  outlineLighten5: "#ffffff",
  outlineLighten4: "#ffffff",
  outlineLighten3: "#ffffff",
  outlineLighten2: "#f4f4f7",
  outlineLighten1: "#e5e4ed",
  outlineLight: "#ffffff",
  outlineBase: "#D6D5E3",
  outlineDark: "#a5a4b1",
  outlineDarken1: "#c7c6d9",
  outlineDarken2: "#b8b6cf",
  outlineDarken3: "#a9a7c4",
  outlineDarken4: "#9a98ba",

  grayLighten5: "#e8e8e8",
  grayLighten4: "#d2d2d2",
  grayLighten3: "#bcbcbc",
  grayLighten2: "#a5a5a5",
  grayLighten1: "#8f8f8f",
  grayBase: "#797979",
  grayDarken1: "#626262",
  grayDarken2: "#4c4c4c",
  grayDarken3: "#363636",
  grayDarken4: "#202020",

  base: "#000000",
};
