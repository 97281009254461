/* Redeam Alert Messages */
export interface MessageQueue {
  messages: Array<RdmMessage>;
}

export interface RdmMessage {
  id: string;
  active: boolean;
  permanent: boolean;
  message: MessageDetail;
  action?: MessageAction;
}

export interface MessageDetail {
  code: number;
  text: string;
  type: MessageType;
}

export interface MessageAction {
  actionId: string; // ID to match action enum
  viewable?: boolean;
  retryable?: boolean;
  undoable?: boolean;
  helpId?: string; // ID to match help text
  helpLink?: string;
  hasHelp?: boolean;
}

export enum MessageType {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

/* Redeam Action Replay Queue */
export interface ActionQueue {
  actions: Array<RdmAction>;
}

export interface RdmAction {
  id: string;
  detail: ActionDetail;
}

export interface ActionDetail {
  code: number;
  button?: ActionButton;
  body?: any;
  method?: ActionMethod;
  endpoint?: string;
  callPromise?: Promise<any>;
}

export enum ActionMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum ActionButton {
  VIEW = "VIEW",
  REDO = "REDO",
  UNDO = "UNDO",
  HELP_INTERNAL = "HELP_INTERNAL",
  HELP_EXTERNAL = "HELP_EXTERNAL",
}

/* Redeam Code Details */
export interface CodeAction {
  message: string;
  viewable?: boolean;
  retryable?: boolean;
  undoable?: boolean;
  helpId?: string; // ID to match help text
  helpLink?: string;
  hasHelp?: boolean;
}

export interface DropdownItem {
  text: string;
  value: string;
}

export interface DisplayDate {
  value: number;
  dayOfWeek: number;
  isInMonth: boolean;
  isInventoryItem: boolean;
  isBlackout: boolean;
  isSoldout: boolean;
  isNoInventory: boolean;
  isoDate: string;
}

export interface DayAvailability {
  date: string;
  original: number;
  sold: number;
  remaining: number;
  blackout: boolean;
}
