import { dinero, toFormat } from "dinero.js";
import * as CURR from "@dinero.js/currencies";
import _ from "lodash";

const transformer = (props: any) => `${props.amount}`;

export function format(dineroObject: any) {
  return toFormat(dineroObject, transformer);
}

export function FromSmallestUnit(amount: number, currency: string) {
  const t = CURR[currency as keyof typeof CURR];
  if (!t) return null;

  const dineroObject = dinero({ amount, currency: t });
  return Number(format(dineroObject)).toFixed(2);
}

// FIXME: wont work with all currencies
export const minorNumberToCurrencyNumber = function (amount: number) {
  if (amount) {
    const amountNumber = _.toNumber(amount);
    return (amountNumber * 0.01).toFixed(2);
  }
  return amount;
};
