import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { ActionButton, ActionQueue, MessageQueue, MessageType, RdmAction, RdmMessage } from "@/models";
import { profileModule } from "@/store/modules/moduleProfile";
import { codes } from "@/utils/codeConstants";
import { httpModule } from "@/store/modules/moduleHttp";
import { codeConfiguration } from "@/app-codes/codeConfiguration";
import router from "@/router";
import { constants } from "@/utils/constants";
import _ from "lodash";
import { userModule } from "@/store/modules/moduleUser";
import { Route } from "vue-router";
import { v4 as uuidv4 } from "uuid";

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-app",
  namespaced: true,
})
class ModuleApp extends VuexModule {
  /* Data */
  private loading = false;
  private isSidebarActive = false;
  private showAboutPage = false;
  private messageQueue = {} as MessageQueue;
  private actionQueue = {} as ActionQueue;
  private actionEventId = "";
  private previousRoute = {} as Route;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get Messages(): MessageQueue {
    return this.messageQueue;
  }

  get Actions(): ActionQueue {
    return this.actionQueue;
  }

  get IsSidebarActive() {
    return this.isSidebarActive;
  }

  get ShowAboutPage() {
    return this.showAboutPage;
  }

  get ActionEventId() {
    return this.actionEventId;
  }

  get PreviousRoute() {
    return this.previousRoute;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setMessages(messages: MessageQueue) {
    this.messageQueue = messages;
  }

  @Mutation
  setActions(actions: ActionQueue) {
    this.actionQueue = actions;
  }

  @Mutation
  setIsSidebarActive(active: boolean) {
    this.isSidebarActive = active;
  }

  @Mutation
  setShowAboutPage(show: boolean) {
    this.showAboutPage = show;
  }

  @Mutation
  setActionEventId(id: string) {
    this.actionEventId = id;
  }

  @Mutation
  setPreviousRoute(route: Route) {
    this.previousRoute = route;
  }

  /* Actions */
  @Action
  addRdmMessage(message: RdmMessage) {
    if (this.messageQueue && this.messageQueue.messages) {
      this.messageQueue.messages.push(message);
      if (!message.permanent) {
        this.timeoutMessage(message);
      }
      return;
    }

    this.setMessages({ messages: [] } as MessageQueue);
    this.messageQueue.messages.push(message);
    if (!message.permanent) {
      this.timeoutMessage(message);
    }
  }

  @Action
  addRdmAction(action: RdmAction) {
    if (this.actionQueue && this.actionQueue.actions) {
      this.actionQueue.actions.push(action);
      return;
    }

    this.setActions({ actions: [] } as ActionQueue);
    this.actionQueue.actions.push(action);
    this.setActionEventId(action.id);
  }

  @Action
  addMessageError(code: number) {
    const id = uuidv4();
    this.addRdmAction({
      id: id,
      detail: {
        code: code,
      },
    });
    const action = codeConfiguration.get(code);
    const message = action ? action.message : "Unknown error";
    this.addRdmMessage({
      id: id,
      permanent: true,
      active: true,
      message: {
        code: code,
        text: message,
        type: MessageType.ERROR,
      },
      action: {
        actionId: id,
        undoable: action?.undoable,
        retryable: action?.retryable,
        viewable: action?.viewable,
        helpId: action?.helpId,
        hasHelp: action?.hasHelp,
        helpLink: action?.helpLink,
      },
    });
  }

  @Action
  addPermanentErrorMsg(errMsg: any) {
    console.log(errMsg);
    const id = uuidv4();
    this.addRdmAction({
      id: id,
      detail: {
        code: 500,
      },
    });
    const action = codeConfiguration.get(500);
    this.addRdmMessage({
      id: id,
      permanent: true,
      active: true,
      message: {
        code: 500,
        text: errMsg,
        type: MessageType.INFO,
      },
      action: {
        actionId: id,
        undoable: action?.undoable,
        retryable: action?.retryable,
        viewable: action?.viewable,
        helpId: action?.helpId,
        hasHelp: action?.hasHelp,
        helpLink: action?.helpLink,
      },
    });
  }

  @Action
  addErrorMsg(errMsg: any) {
    console.log(errMsg);
    const id = uuidv4();
    this.addRdmAction({
      id: id,
      detail: {
        code: 500,
      },
    });
    const action = codeConfiguration.get(500);
    this.addRdmMessage({
      id: id,
      permanent: false,
      active: true,
      message: {
        code: 500,
        text: errMsg,
        type: MessageType.INFO,
      },
      action: {
        actionId: id,
        undoable: action?.undoable,
        retryable: action?.retryable,
        viewable: action?.viewable,
        helpId: action?.helpId,
        hasHelp: action?.hasHelp,
        helpLink: action?.helpLink,
      },
    });
  }

  @Action
  addMessageErr(code: number) {
    const id = uuidv4();
    this.addRdmAction({
      id: id,
      detail: {
        code: code,
      },
    });
    const action = codeConfiguration.get(code);
    const message = action ? action.message : "Unknown error";
    this.addRdmMessage({
      id: id,
      permanent: false,
      active: true,
      message: {
        code: code,
        text: message,
        type: MessageType.INFO,
      },
      action: {
        actionId: id,
        undoable: action?.undoable,
        retryable: action?.retryable,
        viewable: action?.viewable,
        helpId: action?.helpId,
        hasHelp: action?.hasHelp,
        helpLink: action?.helpLink,
      },
    });
  }

  @Action
  addMessageSuccess(code: number | Array<any>) {
    let c = 0;
    let m = undefined;
    if (_.isNumber(code)) {
      c = code;
    }

    if (_.isArray(code) && code.length > 0) {
      c = code[0];
      if (code.length > 1) {
        m = code[1];
      }
    }
    const id = this.ActionEventId && this.ActionEventId.length > 0 ? this.ActionEventId : uuidv4();
    const action = codeConfiguration.get(c);
    const message = m ? m : action ? action.message : "Completed";
    this.addRdmMessage({
      id: id,
      permanent: false,
      active: true,
      message: {
        code: c,
        text: message,
        type: MessageType.INFO,
      },
      action: {
        actionId: id,
        undoable: action?.undoable,
        retryable: action?.retryable,
        viewable: action?.viewable,
        helpId: action?.helpId,
        hasHelp: action?.hasHelp,
        helpLink: action?.helpLink,
      },
    });
  }

  @Action
  timeoutMessage(message: RdmMessage) {
    setTimeout(() => {
      this.removeMessage(message.id);
    }, 5000);
  }

  @Action
  removeMessage(id: string) {
    if (this.messageQueue && this.messageQueue.messages) {
      const i = this.messageQueue.messages.findIndex((message: RdmMessage) => message.id === id);
      if (i > -1) {
        this.messageQueue.messages.splice(i, 1);
      }
    }
  }

  @Action
  removeAction(id: string) {
    if (this.actionQueue && this.actionQueue.actions) {
      const i = this.actionQueue.actions.findIndex((action: RdmAction) => action.id === id);
      if (i > -1) {
        this.actionQueue.actions.splice(i, 1);
      }
    }
  }

  @Action
  getAction(id: string): RdmAction | undefined {
    if (this.actionQueue && this.actionQueue.actions) {
      return this.actionQueue.actions.find((action: RdmAction) => action.id === id);
    }
    return undefined;
  }

  @Action
  clearMessages() {
    if (this.messageQueue && this.messageQueue.messages) {
      this.messageQueue.messages = [];
    }
  }

  @Action
  clearActions() {
    if (this.actionQueue && this.actionQueue.actions) {
      this.actionQueue.actions = [];
    }
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.clearMessages();
    this.clearActions();
    profileModule.clearState();
    userModule.clearState();
  }

  /**
   * Function to handle the retry, undo events
   * @param action
   */
  @Action
  async codeAction(action: RdmAction) {
    try {
      if (action.detail) {
        const code = action.detail.code;
        switch (code) {
          case codes.COMPANY_LOAD:
            await router.push({ name: constants.routes.CONTACTS });
            break;
          case codes.USER_ACTIVATED:
          case codes.USER_CREATED:
            if (action.detail.button === ActionButton.VIEW && action.detail.body && action.detail.body.id) {
              const id = action.detail.body.id;
              await router.push({ name: constants.routes.USER_DETAILS_VIEW, params: { id: id } }).catch();
            }
            break;
          case codes.USER_DEACTIVATED:
            if (action.detail.button === ActionButton.UNDO && action.detail.body && action.detail.body.id) {
              const id = action.detail.body.id;
              await userModule.chooseUserIndexToEdit(id);
              await httpModule.activateUser();
            }
            break;
          default:
            break;
        }
      }
    } catch {
      // todo send message to backend
    }
  }
}

export const appModule = getModule(ModuleApp, store);
