import { EXT_BETA_TICKET_END_DATE, EXT_BETA_TICKET_START_DATE, EXT_DISNEY_TICKET_END_DATE, EXT_DISNEY_TICKET_START_DATE } from "@/api/booking/Extensions";

/**
 * Retrieves the start date for a Disney ticket from the provided extensions object.
 *
 * @param {Object} extensions - An object containing various extension properties.
 * @return {string | null} The start date for the Disney ticket if available, otherwise null.
 */
export function getDisneyTicketStartDate(extensions: any): string | null {
  if (!extensions) {
    return null;
  }
  if (extensions[EXT_DISNEY_TICKET_START_DATE]) {
    return extensions[EXT_DISNEY_TICKET_START_DATE];
  }
  if (extensions[EXT_BETA_TICKET_START_DATE]) {
    return extensions[EXT_BETA_TICKET_START_DATE];
  }
  return null;
}

/**
 * Retrieves the Disney ticket end date from the given extensions object.
 *
 * @param {object} extensions - The object containing potential end date properties.
 * @return {string|null} - The Disney ticket end date if available, otherwise null.
 */
export function getDisneyTicketEndDate(extensions: any): string | null {
  if (!extensions) {
    return null;
  }
  if (extensions[EXT_DISNEY_TICKET_END_DATE]) {
    return extensions[EXT_DISNEY_TICKET_END_DATE];
  }
  if (extensions[EXT_BETA_TICKET_END_DATE]) {
    return extensions[EXT_BETA_TICKET_END_DATE];
  }
  return null;
}
