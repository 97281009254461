import { isArrayNotEmpty } from "@/utils/arrayutils";
import { Price, Retail } from "@/api/rate/price.model";
import { FromSmallestUnit } from "@/utils/currency";

/**
 * hasRetailPrices checks to see if there are any retail prices
 * @param prices
 */
export function hasRetailPrices(prices: Price[]): boolean {
  if (!isArrayNotEmpty(prices)) {
    return false;
  }
  const retailPrices = prices.filter((price) => {
    return price.retail && price.retail.amount;
  });
  return isArrayNotEmpty(retailPrices);
}

/**
 * pricesHaveRetailTaxes for the prices checks to see if there are any retail prices
 * @param prices
 */
export function pricesHaveRetailTaxes(prices: Price[]): boolean {
  if (!isArrayNotEmpty(prices)) {
    return false;
  }
  const pricesWithRetailTax = prices.filter((price) => {
    if (!isArrayNotEmpty(price.includedTaxes)) {
      return false;
    }
    const retailTaxes = price.includedTaxes?.filter((tax) => {
      if (tax.retail && tax.retail > 0) {
        return true;
      }
    });
    return retailTaxes && retailTaxes.length > 0;
  });
  return isArrayNotEmpty(pricesWithRetailTax);
}

/**
 * sortPricesByRetailAmount sorts the prices by retail amount if it exists, if a retail price is not present it is
 * filtered out from the results
 * @param prices
 */
export function sortPricesByRetailAmount(prices: Price[]): Price[] {
  if (!isArrayNotEmpty(prices)) {
    return prices;
  }
  return prices
    .filter((price) => price.retail !== undefined && price.retail.amount !== undefined)
    .sort((a, b) => (a.retail?.amount || 0)! - (b.retail?.amount || 0)!);
}

/**
 * getOrDefaultCurrency returns the currency or defaults to USD
 * @param retail
 */
export function getOrDefaultRetailCurrency(retail: Retail): string {
  if (retail && retail.currency) {
    return retail.currency;
  }
  return "USD";
}

/**
 * getOrDefaultRetailAmount returns the retail amount or default
 * @param price
 */
export function getOrDefaultRetailAmount(price: Price): Retail {
  if (price && price.retail && price.retail.amount) {
    return price.retail;
  }
  return getDefaultRetailAmount();
}

/**
 * Returns a default retail amount object with an amount of 0 and currency set to "USD".
 *
 * @return {Retail} the default retail amount.
 */
export function getDefaultRetailAmount(): Retail {
  return {
    amount: 0,
    currency: "USD",
  };
}

/**
 * Constructs a textual representation of a price range based on an array of price objects.
 * The resulting string will display the lowest and highest prices, and will include a note
 * about whether taxes are included if applicable.
 *
 * @param {Price[]} prices - An array of price objects to analyze and construct the price range from.
 * @return {string} A textual representation of the price range.
 */
export function constructPriceRangeWriteup(prices: Price[]): string {
  const sortedRetailPrices = sortPricesByRetailAmount(prices);
  let highestPrice = sortedRetailPrices.length === 0 ? getDefaultRetailAmount() : getOrDefaultRetailAmount(sortedRetailPrices[0]);
  const lowestPrice = sortedRetailPrices.length === 0 ? getDefaultRetailAmount() : getOrDefaultRetailAmount(sortedRetailPrices[0]);
  if (sortedRetailPrices.length > 1) {
    highestPrice = getOrDefaultRetailAmount(sortedRetailPrices[sortedRetailPrices.length - 1]);
  }
  const currency = getOrDefaultRetailCurrency(lowestPrice);
  const prettyHigh = FromSmallestUnit(highestPrice.amount || 0, getOrDefaultRetailCurrency(highestPrice));
  const prettyLow = FromSmallestUnit(lowestPrice.amount || 0, getOrDefaultRetailCurrency(lowestPrice));

  // taxes
  const hasTaxes = pricesHaveRetailTaxes(sortedRetailPrices);

  // construct price range text
  const priceRange = `Prices range from ${prettyLow} to ${prettyHigh} ${currency}${hasTaxes ? ` (tax included)` : ""}`;
  return priceRange;
}

/**
 * Computes the lowest retail price from a list of Price objects.
 *
 * @param {Price[]} prices - An array of Price objects from which the lowest retail price is to be determined.
 * @return {Retail} The lowest retail price found in the provided list of Price objects.
 */
export function getLowestRetailPrice(prices: Price[]): Retail {
  const sortedRetailPrices = sortPricesByRetailAmount(prices);
  return sortedRetailPrices.length === 0 ? getDefaultRetailAmount() : getOrDefaultRetailAmount(sortedRetailPrices[0]);
}
