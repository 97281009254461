import moment from "moment-timezone";

/**
 * YYYYMMDDFormat - example: 2023-12-31
 */
export const YYYYMMDDFormat = "YYYY-MM-DD";
/**
 * FullDateFormat - example: Sunday, December 31, 2023
 */
export const FullDateFormat = "dddd, MMMM DD, YYYY";
/**
 * FullDateTimeFormat - example: Sunday, December 31, 2023 06:00 PM CST
 */
export const FullDateTimeFormat = "dddd, MMMM DD, YYYY hh:mm A z";

/**
 * MMDDYYYYFormat - example: 12/31/2023
 */
export const MMDDYYYYFormat = "MM/DD/YYYY";

/**
 * HHMMFormat - example: 06:00 PM CST
 */
export const HHMMFormat = "hh:mm A z";

/**
 * formatFullDate - formats the string value into this format: Sunday, December 31, 2023
 * @param value
 */
export const formatFullDate = function (value: string) {
  if (value) {
    return moment(value).format(FullDateFormat);
  }
  return value;
};

/**
 * formatFullDateTime - formats the string value into this format: Sunday, December 31, 2023 06:00 PM
 * @param value
 */
export const formatFullDateTime = function (value: string) {
  if (value) {
    return moment(value).format(FullDateTimeFormat);
  }
  return value;
};

/**
 * formatYMD - formats the string value into this format: 2023-12-31
 * @param value
 */
export const formatYMD = function (value: string) {
  if (value) {
    return moment(value).format(YYYYMMDDFormat);
  }
  return value;
};

/**
 * formatMDY - formats the string value into this format: 12/31/2023
 * @param value
 */
export const formatMDY = function (value: string) {
  if (value) {
    return moment(value).format(MMDDYYYYFormat);
  }
  return value;
};

/**
 * formatMDY - formats the string value into this format: 06:00 AM
 * @param value
 */
export const formatHHMM = function (value: string) {
  if (value) {
    return moment(value).format(HHMMFormat);
  }
  return value;
};
