import axios, { AxiosInstance } from "axios";
import { from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { config } from "@/utils/config";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { PosErrHandlers } from "./ErrHandlers";
import { PointOfSale, setPoSDisplayName } from "@/api/pos/pos.models";

const xsrfCookieName = "XSRF-TOKEN";

export class PosClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.PosApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(PosClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    if (err.response?.status === 401) {
      return PosClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data;
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public getPointOfSales(distributorId: string, errs?: PosErrHandlers): Observable<PointOfSale[]> {
    return from(this.httpClient.get<PointOfSale[]>(`/distributors/${distributorId}/pointsofsale`)).pipe(
      map((rs) => {
        const pointsOfSale: PointOfSale[] = rs.data;
        for (const pos of pointsOfSale) {
          setPoSDisplayName(pos);
        }
        return pointsOfSale;
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onPosNotFound && e.status === 404) {
            errs.onPosNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public getPosDetails(distributorID: string, posID: string, errs?: PosErrHandlers): Observable<PointOfSale> {
    return from(this.httpClient.get<PointOfSale>(`/distributors/${distributorID}/pointsofsale/${posID}`)).pipe(
      map((rs) => {
        const pos = rs.data;
        setPoSDisplayName(pos);
        return pos;
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onPosNotFound && e.status === 404) {
            errs.onPosNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public getParks(startDate: string, endDate: string, errs?: PosErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`/disney/park/availability/${startDate}/${endDate}`)).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onPosNotFound && e.status === 404) {
            errs.onPosNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public getPolicies(url: string, errs?: PosErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`/content/${url}`)).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onPosNotFound && e.status === 404) {
            errs.onPosNotFound(e);
          }
        }, errs),
      ),
    );
  }
}

const posClient = new PosClient();

export default posClient;
