/**
 * Voucher Models - all voucher related models
 */

export interface VoucherPDFRQ {
  bookingID?: string;
  templateID?: string;
  format?: string;
  emailOverrides?: string[];
  parameters?: Parameters;
}

export interface Parameters {
  ContactPhone?: string;
  ResellerName?: string;
  AgentFacing?: boolean;
  WithRetailPricing?: boolean;
  WithNetPricing?: boolean;
  WithOriginalPricing?: boolean;
  MarketRegion?: string;
  DiscountGroup?: string;
  TicketStartDate?: string;
  TicketEndDate?: string;
  TicketValidityDays?: number;
  PickupInstructions?: string;
  AgentName?: string;
  ParkReservationStatus?: ParkReservationStatus;
  ResellerRegion?: ResellerRegion;
}

export enum ResellerRegion {
  Argentina = "Argentina",
  Australia = "Australia",
  Brazil = "Brazil",
  Canada = "Canada",
  Chile = "Chile",
  Colombia = "Colombia",
  Europe = "Europe",
  Ireland = "Ireland",
  Japan = "Japan",
  Mexico = "Mexico",
  NewZealand = "NewZealand",
  Peru = "Peru",
  Quebec = "Quebec",
  UnitedKingdom = "UnitedKingdom",
  UnitedStates = "UnitedStates",
  Other = "Other",
}

export enum ParkReservationStatus {
  None = "none",
  Skipped = "skipped",
  Success = "success",
  Failure = "failure",
  Partial = "partial",
  Error = "error",
}

export enum PickupInstructions {
  Domestic = "Domestic",
  International = "International",
  SpecialEvent = "SpecialEvent",
  USMilitary = "USMilitary",
  FloridaResident = "FloridaResident",
  WorldCertificate = "WorldCertificate",
}
