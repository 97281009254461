import { constants } from "@/utils/constants";

class SessionStorageService {
  get usrMngmtPage() {
    const page = sessionStorage.getItem(constants.sessionstorage.USER_MANAGEMENT_PAGE);
    if (!page) return undefined;
    return parseInt(page);
  }
  set usrMngmtPage(page: number | undefined) {
    page && sessionStorage.setItem(constants.sessionstorage.USER_MANAGEMENT_PAGE, page.toString());
  }
  removeUsrMngmtPage() {
    sessionStorage.removeItem(constants.sessionstorage.USER_MANAGEMENT_PAGE);
  }

  get usrMngmtSelectedRole() {
    const roles = sessionStorage.getItem(constants.sessionstorage.USER_MANAGEMENT_SELECTED_ROLE);
    if (!roles) return undefined;
    return JSON.parse(roles);
  }
  set usrMngmtSelectedRole(roles: Array<string> | undefined) {
    roles && sessionStorage.setItem(constants.sessionstorage.USER_MANAGEMENT_SELECTED_ROLE, JSON.stringify(roles));
  }
  get usrMngmtSelectedPOS() {
    const pos = sessionStorage.getItem(constants.sessionstorage.USER_MANAGEMENT_SELECTED_POS);
    if (!pos) return undefined;
    return JSON.parse(pos);
  }
  set usrMngmtSelectedPOS(pos: Array<string> | undefined) {
    pos && sessionStorage.setItem(constants.sessionstorage.USER_MANAGEMENT_SELECTED_POS, JSON.stringify(pos));
  }

  removeUsrMngmtSelectedRole() {
    sessionStorage.removeItem(constants.sessionstorage.USER_MANAGEMENT_SELECTED_ROLE);
  }

  removeUsrMngmtSelectedPOS() {
    sessionStorage.removeItem(constants.sessionstorage.USER_MANAGEMENT_SELECTED_POS);
  }
}

export const sessionStorageSrv = new SessionStorageService();
