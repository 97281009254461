import { CommonErrHandlers, errHandler } from "@/api/ErrHandlers";
import { getBookingAPIErrorMessage, getBookingAPIPartnerErrorMessage } from "@/api/booking/BookingAPIError";
import { AxiosErrorWithResponse } from "@/api/AxiosInterceptors";

export interface BookingListErrHandlers extends CommonErrHandlers {
  onBookingNotFound?: errHandler;
}

export interface CancelBookingErrHandlers extends CommonErrHandlers {
  cancelBookingNotFound?: errHandler;
}

export interface AvailabilityRSErrHandlers extends CommonErrHandlers {
  onAvailabilityNotFound?: errHandler;
}

export interface HoldRSErrHandlers extends CommonErrHandlers {
  onHoldNotFound?: errHandler;
}

export interface CreateBookingRSErrHandlers extends CommonErrHandlers {
  onCreateBookingNotFound?: errHandler;
}

/**
 * BookingAPIAxiosErrorInterceptor - handles error responses
 * @param err
 */
export function BookingAPIAxiosErrorInterceptor(err: AxiosErrorWithResponse) {
  switch (err.response?.status) {
    case 401:
      return handle401(err);
    case 502:
      return handle502(err);
    case 503:
      return handle503(err);
    default:
      return handleError(err);
  }
}

/**
 * handle401 - return the error
 * @param err
 */
function handle401(err: any): Promise<any> {
  return Promise.reject({
    status: err.response.status,
    message: "unauthorized",
    ...err,
  });
}

/**
 * handle502 - return the partner error details
 * @param err
 */
function handle502(err: any): Promise<any> {
  return Promise.reject({
    status: err.response.status,
    message: getBookingAPIPartnerErrorMessage(err.response.data),
    ...err,
  });
}

/**
 * handle503 - return a maintenance window error
 * @param err
 */
function handle503(err: any): Promise<any> {
  return Promise.reject({
    status: err.response.status,
    message: "Our partner is currently in their maintenance window and is unable to process your request at this time, please try again later.",
    ...err,
  });
}

/**
 * handleError - return the booking API error details
 * @param err
 */
function handleError(err: any): Promise<any> {
  return Promise.reject({
    status: err.response.status,
    message: getBookingAPIErrorMessage(err.response.data),
    ...err,
  });
}
