/** Beta Extensions */
export const EXT_BETA_TICKET_LOCATIONS = "beta-ticketLocations";
export const EXT_BETA_POLICY = "beta-policy";
export const EXT_BETA_AVAILABILITY_PRICING_TYPE = "beta-availabilityPricingType";
export const EXT_BETA_TRAVELER_WEIGHT = "beta-weight";
export const EXT_BETA_TICKET_START_DATE = "beta-ticketStartDate";
export const EXT_BETA_TICKET_END_DATE = "beta-ticketEndDate";

/** Disney Specific Extensions */
export const EXT_DISNEY_SALES_START_DATE = "disney-salesStartDate";
export const EXT_DISNEY_SALES_END_DATE = "disney-salesEndDate";
export const EXT_DISNEY_USAGE_START_DATE = "disney-usageStartDate";
export const EXT_DISNEY_USAGE_END_DATE = "disney-usageEndDate";
export const EXT_DISNEY_TICKET_START_DATE = "disney-ticketStartDate";
export const EXT_DISNEY_TICKET_END_DATE = "disney-ticketEndDate";
export const EXT_DISNEY_PARK_LOCATIONS = "disney-parkLocations";
export const EXT_DISNEY_PARK_RESERVATIONS_REQUIRED = "disney-parkReservationRequired";
export const EXT_DISNEY_PARK_RESERVATION_DATE = "disney-parkReservationDate";
export const EXT_DISNEY_PARK_RESERVATION_STATUS = "disney-parkReservationStatus";
export const EXT_DISNEY_BULK_TICKET_PURCHASE = "disney-bulkTicketPurchase";
export const EXT_DISNEY_BULK_TICKET_DELIVERY_DATE = "disney-deliveryDate";
export const EXT_DISNEY_BULK_TICKET_DELIVERY_INSTRUCTIONS = "disney-deliveryInstructions";
export const EXT_DISNEY_BULK_TICKET_DELIVERY_TYPE = "disney-deliveryType";
export const EXT_DISNEY_DATE_BASED_TICKET = "disney-dateBasedTicket";
export const EXT_DISNEY_DISCOUNT_GROUPS = "disney-discountGroups";
export const EXT_DISNEY_MARKET_REGIONS = "disney-marketRegions";
export const EXT_DISNEY_PRODUCT_DURATION = "disney-productDuration";
export const EXT_DISNEY_BOOKING_BUFFER_DAYS = "disney-bookingBufferDays";
export const EXT_DISNEY_DISTRIBUTOR_NAME = "disney-distributorName";
export const EXT_DISNEY_NET_DISCOUNT = "disney-netDiscount";

/** Reseller Portal Specific Extensions */
export const EXT_RESELLER_PORTAL_AGENT_NAME = "x-resellerPortal.AgentName";
export const EXT_RESELLER_PORTAL_AGENT_EMAIL = "x-resellerPortal.AgentEmail";
export const EXT_RESELLER_PORTAL_RESELLER_NAME = "x-resellerPortal.ResellerName";
export const EXT_RESELLER_PORTAL_RESELLER_REGION = "x-resellerPortal.ResellerRegion";
export const EXT_RESELLER_PORTAL_PICKUP_LOCATION_NAME = "x-resellerPortal.PickupLocationName";
export const EXT_RESELLER_PORTAL_PICKUP_LOCATION_ADDRESS = "x-resellerPortal.PickupLocationAddress";
export const EXT_RESELLER_PORTAL_PICKUP_LOCATION_TIME = "x-resellerPortal.PickupLocationTime";

/* GrandCanyon Specific Extensions */
export const EXT_GRAND_CANYON_GROUP_HOTEL_NAME = "grandcanyongroup-guest_pickup_hotel_name";
export const EXT_GRAND_CANYON_GROUP_PICKUP_LOCATION = "grandcanyongroup-guest_pickup_location";
export const EXT_GRAND_CANYON_GROUP_TIME_LOCAL = "grandcanyongroup-guest_pickup_time_local";
export const EXT_GRAND_CANYON_GROUP_LISTS = "grandcanyongroup-hotel_lists";
export const EXT_GRAND_CANYON_GROUP_LIST = "grandcanyongroup-hotel_list";
export const EXT_GRAND_CANYON_GROUP_HOTEL_ID = "grandcanyongroup-guest_hotel_id";

/** others */
export const EXT_TICKETING_SYSTEM = "x-ticketingSystem";
export const EXT_SUPPLIER_REFERENCE = "supplier.reference";
