import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { appModule } from "@/store/modules/moduleApp";
import { codes } from "@/utils/codeConstants";
import posClient from "@/api/pos/PosClient";
import { profileModule } from "@/store/modules/moduleProfile";
import resellerClient from "@/api/reseller/ResellerClient";
import { ResellerUserConfig, Reseller } from "@/api/reseller/ResellerModels";
import { httpModule } from "./moduleHttp";
import { Account as UserProfile } from "@/api/backplane/BackplaneModels";
import { companyModule } from "@/store/modules/moduleCompany";
import { moduleSuppliers } from "@/store/modules/moduleSuppliers";
import { moduleBookingAdd } from "@/store/modules/moduleBookingAdd";
import { calculateValidityRange } from "@/utils/helpers";
import { Logger } from "@/api/logging/logger";
import { PointOfSale } from "@/api/pos/pos.models";

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-pos",
  namespaced: true,
})
class ModulePos extends VuexModule {
  private logger = new Logger();

  /* Data */
  private loading = false;
  private posLoading = false;
  private resellerData: Reseller | null = null;

  // FIXME: pos is the users PoS, posList is the full list for the distributor
  private pos: Array<PointOfSale> = [];
  private posList: Array<PointOfSale> = [];
  private distributorCode = "";
  private channelID = "";

  private selectedPOS = "";
  private resellerId = "";
  private selectedSupplierID = "";

  private parks: any = [];
  private reservations: any = [];

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get ResellerData() {
    return this.resellerData;
  }

  get User(): UserProfile | null {
    return profileModule.Profile;
  }

  get Pos(): any {
    return this.pos;
  }

  get SelectedPointOfSale(): PointOfSale | undefined {
    return this.pos.find((p) => {
      return p.code === this.selectedPOS;
    });
  }

  get PosList(): any {
    return this.posList;
  }

  get DistributorCode() {
    return this.distributorCode;
  }

  get ChannelID() {
    return this.channelID;
  }

  get SelectedPOS(): any {
    return this.selectedPOS;
  }

  get ResellerId(): string {
    return this.resellerId;
  }

  get PosLoading(): boolean {
    return this.posLoading;
  }

  get SelectedSupplierID(): string {
    return this.selectedSupplierID;
  }

  get Parks() {
    return this.parks;
  }

  get Reservations() {
    return this.reservations;
  }
  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setResellerData(data: any) {
    this.resellerData = data;
  }

  @Mutation
  setPosLoading(posLoading: boolean) {
    this.posLoading = posLoading;
  }

  @Mutation
  setPos(data: Array<PointOfSale>) {
    this.pos = data.sort(function (a: any, b: any) {
      return a.code.localeCompare(b.code);
    });
  }

  @Mutation
  setPosList(data: Array<PointOfSale>) {
    this.posList = data.sort(function (a: any, b: any) {
      return a.code.localeCompare(b.code);
    });
  }

  @Mutation
  setResellerId(resellerId: string) {
    this.resellerId = resellerId;
  }

  @Mutation
  setSelectedPOS(data: string) {
    this.selectedPOS = data;
  }

  @Mutation
  setDistributorCode(data: string) {
    this.distributorCode = data;
  }

  @Mutation
  setChannelID(id: string) {
    this.channelID = id;
  }

  @Mutation
  setSelectedSupplierID(supplierId: string) {
    this.selectedSupplierID = supplierId;
  }

  @Mutation
  setParks(parks: any) {
    this.parks = parks;
  }

  @Mutation
  setReservations(reservations: any) {
    this.reservations = reservations;
  }
  /* Actions */

  @Action
  async getPosList() {
    await Promise.resolve(this.setLoading(true))
      .then(() => {
        if (!profileModule.Profile) {
          return httpModule
            .getProfile()
            .then(() => profileModule.Profile?.id)
            .catch(() => undefined);
        }

        return profileModule.Profile.id;
      })
      .then(async (userID: string | undefined) => {
        if (this.distributorCode) {
          await posClient
            .getPointOfSales(this.distributorCode)
            .toPromise()
            .then((res) => {
              if (Array.isArray(res)) {
                this.setPosList(res);
              }
            });
        }
        return userID;
      })
      .then((userID: string | undefined) => {
        if (userID) {
          const resellerID = companyModule.ResellerId;
          return resellerClient
            .getUserConfig(resellerID, userID)
            .toPromise()
            .catch(() => undefined);
        }
      })
      .then((userConfig: ResellerUserConfig | undefined) => {
        if (userConfig === undefined) {
          return Promise.reject(codes.COMPANY_ERROR_404_LOAD_USER_CONFIG);
        }
        if (!!userConfig && !!userConfig.pointsOfSale) {
          let posList: any[] = [];
          userConfig?.pointsOfSale.forEach((item) => {
            const pos = this.PosList.find((el: any) => el?.code === item);
            posList.push(pos);
          });
          posList = posList.filter((p) => !!p);
          this.setPos(posList);
        }
      })
      .catch((err) => {
        console.log(err);
        const errMessage = err?.response?.data?.message || "Error in POS";
        appModule.addErrorMsg(errMessage);
      })
      .then(() => {
        const isAgentOrManager = profileModule.ISAgent || profileModule.ISManager;
        if (isAgentOrManager && companyModule.Reseller?.distributorID && this.pos?.length > 0) {
          return;
        }

        moduleSuppliers.getSuppliersList();
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  async getPOSDetails() {
    await Promise.resolve(this.setLoading(true))
      .then(() => {
        const reseller = companyModule.Reseller;
        if (!reseller) {
          return Promise.reject(codes.COMPANY_LOAD);
        }
        const distributorCode = reseller.distributorID.split("_");
        return posClient.getPosDetails(distributorCode[0], this.SelectedPOS).toPromise();
      })
      .then((pos) => {
        if (!pos.channelID) {
          return Promise.reject(codes.POS_ERROR_DETAILS);
        }

        this.setChannelID(pos.channelID);
      })
      .then(() => moduleSuppliers.getSuppliersList())
      .catch((err) => appModule.addMessageError(err))
      .finally(() => {
        this.setLoading(false);
      });
  }
  @Action
  async getParks() {
    await Promise.resolve(this.setLoading(true))
      .then(() => {
        const reseller = companyModule.Reseller;
        if (!reseller) {
          return Promise.reject(codes.COMPANY_LOAD);
        }
        const product = moduleBookingAdd.RatesList.find((item: any) => item.id === moduleBookingAdd.SelectedCardVal);
        const validityRange = calculateValidityRange(product?.ext, moduleBookingAdd.SelectedDate);
        return posClient.getParks(validityRange.startDate, validityRange.endDate).toPromise();
      })
      .then((parks: any) => {
        this.setParks(parks);
      })
      .catch((err) => appModule.addMessageError(err))
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  async getPolicies(url: string) {
    await Promise.resolve(this.setLoading(true))
      .then(() => {
        const reseller = companyModule.Reseller;
        if (!reseller) {
          return Promise.reject(codes.COMPANY_LOAD);
        }
        return posClient.getPolicies(url).toPromise();
      })
      .then((policies: any) => {
        this.logger.debug("policies: " + JSON.stringify(policies));
      })
      .catch((err) => appModule.addMessageError(err))
      .finally(() => {
        this.setLoading(false);
      });
  }
}

export const posModule = getModule(ModulePos, store);
