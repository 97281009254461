import axios, { AxiosInstance } from "axios";
import { config } from "@/utils/config";
import { intercept } from "@/api/AxiosInterceptors";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import { GenerateBookingsReportsParams, BookingsReport } from "./ReportsModels";

export class ReportsClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.ReportingApiUrl,
      timeout: parseInt(config.ApiTimeout),
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    // this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(ReportsClient.ErrorResponse));
  }

  public generateBookingsReport(params: GenerateBookingsReportsParams): Promise<BookingsReport> {
    return from(this.httpClient.get<BookingsReport>("/bookings", { params }))
      .pipe(map((rs) => rs.data))
      .toPromise();
  }
}

const reportsClient = new ReportsClient();
export default reportsClient;
