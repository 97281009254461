export interface Reseller {
  id: string;
  name: string;
  orgID: string;
  distributorID: string;
  channelID: string;
  bookingApiKey: string;
  bookingApiSecret: string;
  config?: any;

  meta: Metadata;
}

export interface ResellerContact {
  id: string;
  resellerID: string;
  reference: string;
  name: string;
  email: string;
  type: ResellerContactType;
  phone: string;
  version: number;

  meta: Metadata;
}

export interface ResellerUserConfig {
  id: string;
  resellerID: string;
  email: string;
  pointsOfSale: string[];
}

export interface Metadata {
  version?: number;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum ResellerContactType {
  RESELLER = "RESELLER",
  DISTRIBUTOR = "DISTRIBUTOR",
  POS = "POS",
  AGENT = "AGENT",
  UNKNOWN = "UNKNOWN",
}

export interface DisabledPricingRQ {
  channels?: string;
  supplierId?: string;
  productId?: string;
  rateId?: string;
  userId?: string;
}

export interface DeactiveTicketsRQ {
  channels?: string;
  supplierId?: string;
}

export interface ListDeactiveTickets {
  posId: string;
  supplierId: string;
  productId: string;
  rateId: string;
  disabledAt: string;
  disabledBy: string;
}
