export const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "USD",
    },
  },
  de: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
  },
  ja: {
    currency: {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "symbol",
    },
  },
};
