export const codes = {
  /* Profile (10000 - 10999) */
  PROFILE_LOAD: 10000,
  PROFILE_CREATED: 10001,
  PROFILE_UPDATED: 10002,
  PROFILE_DELETED: 10003,
  PROFILE_ERROR_500_LOAD_PROFILE: 10101,
  PROFILE_ERROR_500_CREATED_PROFILE: 10102,
  PROFILE_ERROR_404_UPDATED_PROFILE: 10103,
  PROFILE_ERROR_500_UPDATED_PROFILE: 10104,
  PROFILE_ERROR_404_DELETED_PROFILE: 10105,
  PROFILE_ERROR_500_DELETED_PROFILE: 10106,
  PROFILE_ERROR_UNAUTHORIZED: 10107,
  /* User (11000 - 11999) */
  USER_LOAD: 11000,
  USER_CREATED: 11001,
  USER_UPDATED: 11002,
  USER_DELETED: 11003,
  USER_DEACTIVATED: 11004,
  USER_ACTIVATED: 11005,
  USER_ERROR_500_LOAD_USER: 11101,
  USER_ERROR_500_CREATE_USER: 11102,
  USER_ERROR_404_UPDATE_USER: 11103,
  USER_ERROR_500_UPDATE_USER: 11104,
  USER_ERROR_404_DELETE_USER: 11105,
  USER_ERROR_500_DELETE_USER: 11106,
  USER_ERROR_500_DEACTIVATED_USER: 11107,
  USER_ERROR_500_ACTIVATED_USER: 11108,
  USER_ERROR_401_CREATE_USER: 11109,
  /* Pricing (44000 - 44999) */
  TICKET_DEACTIVATED: 44001,
  TICKET_ERROR_500_DEACTIVATED_TICKET: 44002,
  TICKET_ERROR_404_DEACTIVATE_TICKET: 44003,
  TICKET_ERROR_400_DEACTIVATE_TICKET: 44004,
  TICKET_ACTIVATED: 44005,
  TICKET_ERROR_500_ACTIVATED_TICKET: 44006,
  TICKET_ERROR_404_ACTIVATE_TICKET: 44007,
  TICKET_ERROR_400_ACTIVATE_TICKET: 44008,

  /* Booking (33000 - 33999) */
  POS_ERROR_LOAD: 33001,
  SUPPLIERS_ERROR_LOAD: 33002,
  PRODUCTS_ERROR_LOAD: 33003,
  RATES_ERROR_LOAD: 33004,
  PRICESCHEDULE_ERROR_LOAD: 33005,
  AVAILABILITY_ERROR: 33006,
  ACQUIRE_HOLD_ERROR: 33007,
  CREATE_BOOKING_ERROR: 33008,
  CREATE_BOOKING_SUCCESS: 33010,
  CANCEL_BOOKING_SUCCESS: 33011,
  POS_ERROR_DETAILS: 33012,
  POS_ERROR_404_UPDATE_POS: 33013,
  POS_ERROR_500_UPDATE_POS: 33014,
  BOOKING_CONFIRMATION_SUCCESS: 33020,
  DISNEY_CONFIRMATION_SUCCESS: 33021,

  SUPPLIERS_ERROR_RQ: 33101,
  PRODUCTS_ERROR_RQ: 33102,
  RATES_ERROR_RQ: 33103,
  PRICESCHEDULE_ERROR_RQ: 33104,
  AVAILABILITY_ERROR_RQ: 33105,
  ACQUIRE_HOLD_ERROR_RQ: 33106,
  CREATE_BOOKING_ERROR_RQ: 33107,
  BOOKING_NOT_FOUND_ERROR: 33108,
  CART_EMPTY_ERROR: 33110,
  BOOKING_CONFIRMATION_ERROR: 33120,
  DISNEY_CONFIRMATION_ERROR: 33121,
  POS_NOT_FOUND: 33122,

  /* Pricing (55000 - 55999) */
  CREATE_PRICING_SUCCESS: 55001,
  UPDATE_PRICING_SUCCESS: 55002,
  DELETE_PRICING_SUCCESS: 55003,
  GET_PRICING_SUCCESS: 55004,
  CREATE_PRICING_SAMEDATE: 55005,
  CREATE_PRICING_OVERLAP: 55006,
  SELECT_DATES_RANGE: 55007,
  SELECT_ADJUSTMENT_TYPE: 55008,
  SELECT_ADJUSTMENT_VAL: 55009,
  SELECT_SUPPLIER_ID: 55010,
  SELECT_CHANNEL_ID: 55011,
  SELECT_PRODUCT_ID: 55012,
  SELECT_TICKET_ID: 55013,

  CREATE_PRICING_ERROR: 55014,
  UPDATE_PRICING_ERROR: 55015,
  DELETE_PRICING_ERROR: 55016,
  GET_PRICING_ERROR: 55017,
  ORIGINAL_AMOUNT_ERROR: 55018,
  NET_AMOUNT_ERROR: 55019,
  RETAIL_AMOUNT_ERROR: 55020,

  SELECT_START_DATE: 55021,
  SELECT_END_DATE: 55022,
  START_DATE_ERROR: 55023,
  END_DATE_ERROR: 55024,

  /* Company (20000 - 20999) */
  COMPANY_LOAD: 20000,
  COMPANY_CREATED: 20001,
  COMPANY_UPDATED: 20002,
  COMPANY_DELETED: 20003,
  COMPANY_LOAD_ADDRESS: 20004,
  COMPANY_LOAD_SUPPLIER_ADDRESS: 20005,
  COMPANY_LOAD_PRODUCT_ADDRESS: 20006,
  COMPANY_CREATE_COMPANY_ADDRESS: 20007,
  COMPANY_UPDATE_COMPANY_ADDRESS: 20008,
  COMPANY_DELETE_COMPANY_ADDRESS: 20009,
  COMPANY_CREATE_SUPPLIER_ADDRESS: 20010,
  COMPANY_CREATE_SUPPLIER_BULK_ADDRESS: 20011,
  COMPANY_UPDATE_SUPPLIER_ADDRESS: 20012,
  COMPANY_DELETE_SUPPLIER_ADDRESS: 20013,
  COMPANY_CREATE_PRODUCT_ADDRESS: 20014,
  COMPANY_UPDATE_PRODUCT_ADDRESS: 20015,
  COMPANY_DELETE_PRODUCT_ADDRESS: 20016,
  COMPANY_ERROR_404_LOAD_COMPANY: 20101,
  COMPANY_ERROR_500_LOAD_COMPANY: 20102,
  COMPANY_ERROR_500_CREATE_COMPANY: 20103,
  COMPANY_ERROR_404_UPDATE_COMPANY: 20104,
  COMPANY_ERROR_500_UPDATE_COMPANY: 20105,
  COMPANY_ERROR_404_DELETED_COMPANY: 20106,
  COMPANY_ERROR_500_DELETED_COMPANY: 20107,
  COMPANY_ERROR_404_LOAD_ADDRESS: 20108,
  COMPANY_ERROR_500_LOAD_ADDRESS: 20109,
  COMPANY_ERROR_404_LOAD_SUPPLIER_ADDRESS: 20110,
  COMPANY_ERROR_500_LOAD_SUPPLIER_ADDRESS: 20111,
  COMPANY_ERROR_404_LOAD_PRODUCT_ADDRESS: 20112,
  COMPANY_ERROR_500_LOAD_PRODUCT_ADDRESS: 20113,
  COMPANY_ERROR_404_CREATE_COMPANY_ADDRESS: 20114,
  COMPANY_ERROR_500_CREATE_COMPANY_ADDRESS: 20115,
  COMPANY_ERROR_404_UPDATE_COMPANY_ADDRESS: 20116,
  COMPANY_ERROR_500_UPDATE_COMPANY_ADDRESS: 20117,
  COMPANY_ERROR_404_DELETED_COMPANY_ADDRESS: 20118,
  COMPANY_ERROR_500_DELETED_COMPANY_ADDRESS: 20119,
  COMPANY_ERROR_404_CREATE_SUPPLIER_ADDRESS: 20120,
  COMPANY_ERROR_500_CREATE_SUPPLIER_ADDRESS: 20121,
  COMPANY_ERROR_404_CREATE_SUPPLIER_BULK_ADDRESS: 20122,
  COMPANY_ERROR_500_CREATE_SUPPLIER_BULK_ADDRESS: 20123,
  COMPANY_ERROR_404_UPDATE_SUPPLIER_ADDRESS: 20124,
  COMPANY_ERROR_500_UPDATE_SUPPLIER_ADDRESS: 20125,
  COMPANY_ERROR_404_DELETED_SUPPLIER_ADDRESS: 20126,
  COMPANY_ERROR_500_DELETED_SUPPLIER_ADDRESS: 20127,
  COMPANY_ERROR_404_CREATE_PRODUCT_ADDRESS: 20128,
  COMPANY_ERROR_500_CREATE_PRODUCT_ADDRESS: 20129,
  COMPANY_ERROR_404_UPDATE_PRODUCT_ADDRESS: 20130,
  COMPANY_ERROR_500_UPDATE_PRODUCT_ADDRESS: 20131,
  COMPANY_ERROR_404_DELETED_PRODUCT_ADDRESS: 20132,
  COMPANY_ERROR_500_DELETED_PRODUCT_ADDRESS: 20133,
  COMPANY_CONTACT_UPDATED: 20134,
  COMPANY_ERROR_404_LOAD_CONTACTS: 20135,
  COMPANY_ERROR_500_LOAD_CONTACTS: 20136,
  COMPANY_ERROR_404_LOAD_USER_CONFIG: 20137,
  COMPANY_CONTACT_DELETED: 20138,
  COMPANY_ERROR_404_LOAD_RESELLER: 20139,
  /* Product (21000 - 21999) */
  PRODUCT_LOAD: 21000,
  PRODUCT_CREATED: 21001,
  PRODUCT_UPDATED: 21002,
  PRODUCT_DELETED: 21003,
  PRODUCT_ERROR_500_LOAD_COMPANY: 21101,
  PRODUCT_ERROR_500_CREATE_COMPANY: 21102,
  PRODUCT_ERROR_404_UPDATE_COMPANY: 21103,
  PRODUCT_ERROR_500_UPDATE_COMPANY: 21104,
  PRODUCT_ERROR_404_DELETE_COMPANY: 21105,
  PRODUCT_ERROR_500_DELETE_COMPANY: 21106,
  /* Option (22000 - 22999) */
  OPTION_LOAD: 22000,
  OPTION_CREATED: 22001,
  OPTION_UPDATED: 22002,
  OPTION_DELETED: 22003,
  OPTION_ERROR_500_LOAD_OPTION: 22101,
  OPTION_ERROR_500_CREATE_OPTION: 22102,
  OPTION_ERROR_404_UPDATE_OPTION: 22103,
  OPTION_ERROR_500_UPDATE_OPTION: 22104,
  OPTION_ERROR_404_DELETE_OPTION: 22105,
  OPTION_ERROR_500_DELETE_OPTION: 22106,
  /* Traveler Types (23000 - 23999) */
  TRAVELER_TYPE_LOAD: 23000,
  TRAVELER_TYPE_CREATED: 23001,
  TRAVELER_TYPE_UPDATED: 23002,
  TRAVELER_TYPE_DELETED: 23003,
  TRAVELER_TYPE_ERROR_500_LOAD: 23100,
  TRAVELER_TYPE_ERROR_500_CREATE_TRAVELER_TYPE: 23101,
  TRAVELER_TYPE_ERROR_404_UPDATE_TRAVELER_TYPE: 23102,
  TRAVELER_TYPE_ERROR_500_UPDATE_TRAVELER_TYPE: 23103,
  TRAVELER_TYPE_ERROR_404_DELETE_TRAVELER_TYPE: 23104,
  TRAVELER_TYPE_ERROR_500_DELETE_TRAVELER_TYPE: 23105,
  TRAVELER_TYPE_ERROR_MALFORMED_DELETE_TRAVELER_TYPE: 23106,
  /* Price Schedules (24000 - 24999) */
  PRICE_SCHEDULE_LOAD: 24000,
  PRICE_SCHEDULE_CREATED: 24001,
  PRICE_SCHEDULE_UPDATED: 24002,
  PRICE_SCHEDULE_DELETED: 24003,
  PRICE_AND_TAXES_LOAD: 24004,
  PRICE_AND_TAXES_CREATED: 24005,
  PRICE_AND_TAXES_UPDATED: 24006,
  PRICE_AND_TAXES_DELETED: 24007,
  PRICE_LOAD: 24008,
  PRICE_CREATED: 24009,
  PRICE_UPDATED: 24010,
  PRICE_DELETED: 24011,
  PRICE_SCHEDULE_ERROR_500_LOAD_PRICES: 24100,
  PRICE_SCHEDULE_ERROR_500_CREATE_PRICE_SCHEDULE: 24101,
  PRICE_SCHEDULE_ERROR_404_UPDATE_PRICE_SCHEDULE: 24102,
  PRICE_SCHEDULE_ERROR_500_UPDATE_PRICE_SCHEDULE: 24103,
  PRICE_SCHEDULE_ERROR_404_REMOVE_PRICE_SCHEDULE: 24104,
  PRICE_SCHEDULE_ERROR_500_REMOVE_PRICE_SCHEDULE: 24105,
  PRICE_AND_TAXES_ERROR_500_LOAD_PRICES: 24106,
  PRICE_AND_TAXES_ERROR_500_CREATE_PRICE: 24107,
  PRICE_AND_TAXES_ERROR_404_UPDATE_PRICE: 24108,
  PRICE_AND_TAXES_ERROR_500_UPDATE_PRICE: 24109,
  PRICE_AND_TAXES_ERROR_404_REMOVE_PRICE: 24110,
  PRICE_AND_TAXES_ERROR_500_REMOVE_PRICE: 24111,
  PRICE_ERROR_500_LOAD_PRICES: 24112,
  PRICE_ERROR_500_CREATE_PRICE: 24113,
  PRICE_ERROR_404_UPDATE_PRICE: 24114,
  PRICE_ERROR_500_UPDATE_PRICE: 24115,
  PRICE_ERROR_404_REMOVE_PRICE: 24116,
  PRICE_ERROR_500_REMOVE_PRICE: 24117,
  /* Taxes and Fees (25000 - 25999) */
  TAXES_AND_FEES_LOAD: 25000,
  TAXES_AND_FEES_CREATED: 25001,
  TAXES_AND_FEES_UPDATED: 25002,
  TAXES_AND_FEES_DELETED: 25003,
  TAXES_AND_FEES_ERROR_500_LOAD_TAXES: 25100,
  TAXES_AND_FEES_ERROR_500_CREATE_TAXES: 25101,
  TAXES_AND_FEES_ERROR_404_UPDATE_TAXES: 25102,
  TAXES_AND_FEES_ERROR_500_UPDATE_TAXES: 25103,
  TAXES_AND_FEES_ERROR_404_DELETE_TAXES: 25104,
  TAXES_AND_FEES_ERROR_500_DELETE_TAXES: 25105,
  TAXES_AND_FEES_FEEDBACK_SENT: 25106,
  /* Inventory (26000 - 26999) */
  /* Availability Status (27000 - 27999) */
  /* Channels (28000 - 28999) */
  CHANNELS_FEEDBACK_SENT: 28000,
  /* Reports (29000 - 29999) */
  REPORTS_ERROR_400_SEARCH: 29000,
  REPORTS_ERROR_500_SEARCH: 29001,
  /* Reservations (30000 - 30999)*/
  RESERVATIONS_LOAD_ERROR: 30000,
  /* General Messages */
  GENERAL_MESSAGE_NO_ORG_ID: 90000,
  GENERAL_MESSAGE_NO_SUPPLIER_ID: 90001,
};
