import axios, { AxiosInstance } from "axios";
import { EMPTY, from } from "rxjs";
import { config } from "@/utils/config";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { catchError } from "rxjs/operators";
import { LogRecord } from "@/api/logging/logging.models";

const xsrfCookieName = "XSRF-TOKEN";
const xsrfHeaderName = "X-CSRF-Token";

/**
 * LoggingClient is used to log to the backend service
 */
export class LoggingClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.LoggingApiURL,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: xsrfHeaderName,
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(LoggingClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    if (err.response?.status === 401) {
      return Promise.reject(err);
    }
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  /**
   * log - posts log record data to the backend service
   * @param logRecord
   */
  public async log(logRecord: LogRecord): Promise<any> {
    return from(this.httpClient.post<any>(``, JSON.stringify(logRecord))).pipe(
      // Ignore both the response and errors
      catchError((err) => {
        console.log(`error posting logs; cause ${err}`);
        return EMPTY;
      }),
    );
  }
}

const loggingClient: LoggingClient = new LoggingClient();
export default loggingClient;
