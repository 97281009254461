import axios, { AxiosInstance } from "axios";
import { from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { config } from "@/utils/config";
import { intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { Booking, BookingDetailsRS, BookingItem, BookingListRS, BookingRQ } from "./BookingModels";
import _ from "lodash";
import { BookingAPIAxiosErrorInterceptor, BookingListErrHandlers, CreateBookingRSErrHandlers } from "./ErrHandlers";
import { constants } from "@/utils/constants";
import { EXT_SUPPLIER_REFERENCE } from "@/api/booking/Extensions";

const moment = require("moment-timezone");

const xsrfCookieName = "XSRF-TOKEN";
const bookingListApi = "/get-booking-list";
const createBookingApi = "/create-booking";

export class BookingClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.BookingApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(BookingAPIAxiosErrorInterceptor));
  }

  private bookingList(query: any, errs?: BookingListErrHandlers): Observable<BookingListRS> {
    return from(
      this.httpClient.get<any>(`${bookingListApi}`, {
        params: query,
      }),
    ).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onBookingNotFound && e.status === 404) {
            errs.onBookingNotFound(e);
          }
        }, errs),
      ),
    );
  }

  /**
   * Searches for any bookings matching the search parameter
   *
   * @param searchParam
   */
  public async getBookingList(searchParam: string): Promise<any> {
    try {
      const searchQueryParam = this.constructBookingSearchQuery(searchParam);
      const bookingList = await this.bookingList({ searchParam: searchQueryParam }).toPromise();

      if (bookingList?.bookings) {
        return this.mapBookingRS(bookingList);
      } else {
        return bookingList;
      }
    } catch (error) {
      return [];
    }
  }

  /**
   * Constructs a booking search query based on the provided search parameter.
   * This will search for a match on any of: email, firstName, lastName, barcode or resellerBookingRef
   */
  private constructBookingSearchQuery(searchParam: string): string {
    return `email=${searchParam}&first_name=${searchParam}&last_name=${searchParam}&barcode_value=${searchParam}&reseller_booking_ref=${searchParam}&boolean_or_connector=true&limit=${constants.MAX_BOOKING_SEARCH_RESULTS}`;
  }

  private mapBookingRS(bookingList: BookingListRS) {
    const data: any = [];
    if (!Array.isArray(bookingList?.bookings)) return data;

    bookingList?.bookings?.forEach((booking: Booking) => {
      const timezone = booking?.items?.[0]?.rate?.hours?.[0].timezone || "UTC";
      const bookingDate = _.find(booking?.timeline, { typeOf: "OPEN" })?.timestamp || "";
      const supplierBookingRef: string = booking?.ext?.[EXT_SUPPLIER_REFERENCE] || "";

      const bookingItem: BookingItem = {
        id: booking?.id,
        resellerBookingRef: booking?.resellerBookingRef,
        supplierBookingRef: supplierBookingRef,
        status: booking?.status,
        firstName: booking?.customer?.firstName,
        lastName: booking?.customer?.lastName,
        email: booking?.customer?.email,
        phone: booking?.customer?.phone,
        confirmation: this.getConfirmation(booking, timezone),
        bookedDate: bookingDate,
      };

      data.push(bookingItem);
    });

    return data;
  }

  public getConfirmation(booking: any, timezone: any) {
    const confirmBarcode = booking?.tickets?.[0]?.barcode?.value || booking?.status || "";
    const confirmDate = booking?.items?.[0]?.startTime || "";
    let confirmDateDisplay = confirmDate ? moment(confirmDate) : "";
    if (confirmDateDisplay != "") {
      if (timezone != "") {
        confirmDateDisplay = confirmDateDisplay.tz(timezone);
      } else {
        confirmDateDisplay = confirmDateDisplay.utc();
      }
      confirmDateDisplay = confirmDateDisplay.format("dddd, MMMM DD, YYYY");
    }

    const travellerCount = booking?.tickets?.length || 0;
    const travellerInfo = travellerCount && travellerCount > 1 ? travellerCount + " Tickets" : travellerCount ? travellerCount + " Ticket" : "";
    const bookingStatus: string | undefined = booking?.status === "OPEN" ? confirmBarcode : booking?.status;
    const confirmString = bookingStatus + "<br/>" + confirmDateDisplay + "<br/>" + travellerInfo;
    return confirmString;
  }

  public createBooking(req: BookingRQ, errs?: CreateBookingRSErrHandlers): Observable<BookingDetailsRS> {
    return from(this.httpClient.post<any>(`${createBookingApi}`, JSON.stringify(req))).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onCreateBookingNotFound && e.status === 404) {
            errs.onCreateBookingNotFound(e);
          }
        }, errs),
      ),
    );
  }
}

const bookingClient = new BookingClient();

export default bookingClient;
