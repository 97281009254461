import axios, { AxiosInstance } from "axios";
import { forkJoin, from, Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { config } from "@/utils/config";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { ProductsListRS, ProductsRS } from "./ProductModels";
import _ from "lodash";
import { ProductErrHandlers } from "./ErrHandlers";
import rateClient from "@/api/rate/RateClient";
import moment from "moment";
import { extractTimezoneFromRates } from "@/utils/helpers";
import { appModule } from "@/store/modules/moduleApp";
import { codes } from "@/utils/codeConstants";

const xsrfCookieName = "XSRF-TOKEN";
const productDetailsApi = "/product-details";
const productListApi = "/products-list";

export class ProductsClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.ProductsApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(ProductsClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    if (err.response?.status === 401) {
      return ProductsClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data;
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  /* configure client */

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    ProductsClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }

  /* products details */

  private productsDetails(params: any, errs?: ProductErrHandlers): Observable<ProductsRS> {
    return from(this.httpClient.get<any>(`${productDetailsApi}` + "/" + params.supplierId + "/" + params.productId)).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onProductNotFound && e.status === 404) {
            errs.onProductNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getProductDetails(params: any): Promise<any> {
    const productsInfo = await this.productsDetails(params).toPromise();

    if (productsInfo?.product) {
      return this.mapProductInfoRS(productsInfo);
    } else {
      return productsInfo;
    }
  }

  private mapProductInfoRS(productsInfo: ProductsRS) {
    return productsInfo?.product;
  }

  /** Products List */

  private getProducts(params: any, errs?: ProductErrHandlers): Observable<ProductsListRS> {
    return from(this.httpClient.get<any>(`${productListApi}` + "/" + params.supplierId + "/products")).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onProductNotFound && e.status === 404) {
            errs.onProductNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getProductsList(params: any): Promise<any> {
    const productsList = await this.getProducts(params).toPromise();

    if (productsList?.products) {
      return this.mapProductListRS(productsList);
    } else {
      return productsList;
    }
  }

  private mapProductListRS(productsList: ProductsListRS) {
    return productsList?.products;
  }

  private getProductsLS(params: any, errs?: ProductErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`${productListApi}` + "/" + params.supplierId + "/products")).pipe(
      switchMap((rs) => {
        if (!rs.data?.products?.length) return of("No products available");

        const prd: Observable<any>[] = [];

        rs.data?.products.forEach(async (product: any) => {
          const ps: Observable<any> = from(
            rateClient.getRatesList({
              supplierId: params.supplierId,
              productId: product.id,
              los: product.octoId,
              startDate: params.startDate,
              endDate: params.endDate,
            }),
          ).pipe(
            map((ratesData: any) => {
              return {
                ...product,
                rates: ratesData,
              };
            }),
            catchError(() => {
              // FIXME: alert and do not continue...
              appModule.addMessageError(codes.RATES_ERROR_LOAD);
              return of({});
            }),
          );
          prd.push(ps);
        });

        return forkJoin(prd);
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onProductNotFound && e.status === 404) {
            errs.onProductNotFound(e);
          }
        }, errs),
      ),
    );
  }

  private getProductsByMonthLS(params: any, errs?: ProductErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`${productListApi}` + "/" + params.supplierId + "/products")).pipe(
      switchMap((rs) => {
        if (!rs.data?.products?.length) return of("No products available");

        const prd: Observable<any>[] = [];

        rs.data?.products.forEach(async (product: any) => {
          const ps: Observable<any> = from(
            rateClient.getRatesListByMonth({
              supplierId: params.supplierId,
              productId: product.id,
              los: product.octoId,
              startDate: params.startDate,
              endDate: params.endDate,
            }),
          ).pipe(
            map((ratesData: any) => {
              return {
                ...product,
                rates: ratesData,
              };
            }),
            catchError(() => {
              return of({
                ...product,
                rates: [],
              });
            }),
          );
          prd.push(ps);
        });

        return forkJoin(prd);
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onProductNotFound && e.status === 404) {
            errs.onProductNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getProductsAll(params: any): Promise<any> {
    return this.getProductsLS(params)
      .toPromise()
      .then((res) => (res?.products ? res?.products : res));
  }

  public async getProductsAllByMonth(params: any): Promise<any> {
    return this.getProductsByMonthLS(params)
      .toPromise()
      .then((res) => {
        const daysWithAvailability: Map<string, boolean> = new Map();
        if (Array.isArray(res) && res.length > 0) {
          const timezone = extractTimezoneFromRates(res[0].rates);
          const momentToday = moment.tz(timezone).format("YYYY-MM-DD");
          for (const r of res) {
            if (Array.isArray(r.rates)) {
              for (const rate of r.rates) {
                if (Array.isArray(rate.avData)) {
                  for (const av of rate.avData) {
                    if (_.isNumber(av.capacity) && av.capacity > 0) {
                      const momentAvailDate = moment.tz(av.start, "YYYY-MM-DDTHH:mm:ssZ", timezone).format("YYYY-MM-DD");
                      if (!daysWithAvailability.has(momentAvailDate) && moment(momentAvailDate).isSameOrAfter(momentToday)) {
                        daysWithAvailability.set(momentAvailDate, true);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        return Array.from(daysWithAvailability.keys());
      });
  }

  private productsAndRatesRS(params: any, errs?: ProductErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`${productListApi}` + "/" + params.supplierId + "/products")).pipe(
      switchMap((rs) => {
        if (!rs.data?.products?.length) return of("No products available");

        const prd: Observable<any>[] = [];

        rs.data?.products.forEach(async (product: any) => {
          const ps: Observable<any> = from(
            rateClient.getRatesPriceSchedule({
              supplierId: params.supplierId,
              productId: product.id,
            }),
          ).pipe(
            map((ratesData: any) => {
              return {
                ...product,
                rates: ratesData,
              };
            }),
            catchError((err) => {
              return of({
                ...product,
                rates: err,
              });
            }),
          );
          prd.push(ps);
        });

        return forkJoin(prd);
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onProductNotFound && e.status === 404) {
            errs.onProductNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getProductsAndRates(params: any): Promise<any> {
    const productsRates = await this.productsAndRatesRS(params).toPromise();
    if (productsRates?.products) {
      return productsRates?.products;
    } else {
      return productsRates;
    }
  }

  private getProductRatesRS(params: any, errs?: ProductErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`${productDetailsApi}` + "/" + params.supplierId + "/" + params.productId)).pipe(
      switchMap((rs) => {
        if (!rs.data?.product) return of("No products available");

        const product = rs.data?.product;
        const prd: Observable<any>[] = [];

        const ps: Observable<any> = from(
          rateClient.getRatesWithPricing({
            supplierId: params.supplierId,
            productId: product.id,
            los: product.octoId,
            startDate: params.startDate,
            endDate: params.endDate,
          }),
        ).pipe(
          map((ratesData: any) => {
            return {
              ...product,
              rates: ratesData,
            };
          }),
          catchError((err) => {
            return of({
              ...product,
              rates: err,
            });
          }),
        );

        prd.push(ps);

        return forkJoin(prd);
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onProductNotFound && e.status === 404) {
            errs.onProductNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getProductRatesAll(params: any): Promise<any> {
    const productsRatesAll = await this.getProductRatesRS(params).toPromise();

    if (productsRatesAll?.products) {
      return this.mapProductRatesRS(productsRatesAll);
    } else {
      return productsRatesAll;
    }
  }

  private mapProductRatesRS(productsRatesAll: ProductsListRS) {
    return productsRatesAll?.products;
  }
}

const productsClient = new ProductsClient();

export default productsClient;
