import axios, { AxiosInstance } from "axios";
import { from, of, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { config } from "@/utils/config";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { Supplier, SupplierDetailsRS, SuppliersListRS } from "./SupplierModels";
import { SuppliersErrHandlers } from "./ErrHandlers";
import { EXT_DISNEY_DISTRIBUTOR_NAME, EXT_TICKETING_SYSTEM } from "@/api/booking/Extensions";
import { isDefinedAndNotEmpty } from "@/utils/stringutils";

const xsrfCookieName = "XSRF-TOKEN";

export class SuppliersClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.SuppliersApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(SuppliersClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    if (err.response?.status === 401) {
      return SuppliersClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data;
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  /* configure client */

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    SuppliersClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }

  private suppliersDetails(supplierId: string, errs?: SuppliersErrHandlers): Observable<SupplierDetailsRS> {
    return from(this.httpClient.get<any>(`/${supplierId}`)).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onSupplierNotFound && e.status === 404) {
            errs.onSupplierNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getSupplierDetails(supplierId: string): Promise<any> {
    return this.suppliersDetails(supplierId)
      .toPromise()
      .then((suppliersInfo) => (suppliersInfo?.supplier ? suppliersInfo?.supplier : suppliersInfo));
  }

  /**
   * Fetches and processes a list of suppliers from a remote source.
   *
   * @return {Promise<Supplier[]>} A promise that resolves to an array of processed Supplier objects.
   */
  public getSuppliers(): Promise<any> {
    return from(this.httpClient.get<SuppliersListRS>(""))
      .pipe(
        map((res) => {
          if (res.data?.suppliers) {
            const suppliers = res.data?.suppliers;
            suppliers.sort((a: Supplier, b: Supplier) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            suppliers.forEach((supplier: Supplier) => {
              this.overrideSupplierName(supplier);
            });
            return suppliers;
          }
        }),
        catchError((err) => {
          console.error("Error fetching suppliers", err);
          return of<Supplier[]>([]);
        }),
      )
      .toPromise();
  }

  /**
   * if this is a Disney supplier then override the name if the distributor name is present
   * @param supplier
   * @private
   */
  private overrideSupplierName(supplier: Supplier): Supplier {
    let isDisney = false;
    if (supplier.ext?.[EXT_TICKETING_SYSTEM] && supplier.ext?.[EXT_TICKETING_SYSTEM] === "Disney") {
      isDisney = true;
    }
    if (isDisney) {
      const distributorName = supplier.ext?.[EXT_DISNEY_DISTRIBUTOR_NAME] || "";
      if (isDefinedAndNotEmpty(distributorName)) {
        supplier.name = `${supplier.name} (${distributorName})`;
      }
    }
    return supplier;
  }
}

const suppliersClient = new SuppliersClient();

export default suppliersClient;
