import { constants } from "@/utils/constants";
import { config } from "@/utils/config";
import { RouteConfig } from "vue-router";

// routes
// ├── * (not found)
// └── layoutRoutes
//     ├──publicRoutes (FullPage.vue)
//     ├──publicRoutesWIP (FullPage.vue)
//     ├──privateRoutesWIP (Main.vue)
//     └──privateRoutes (Main.vue)
//        ├──companySubRoutes
//        ├──productsSubRoutes
//        ├──oneProductSubRoutes
//        └── ... (other sub routes for nested router views)
//
// P.S. WIP - routes, which are either:
// - not supposed to be in the next production release
// - require some updates to their RouteConfig

// =============================================================================
// PRIVATE(auth required) ROUTES FOR Main LAYOUT
// =============================================================================

const pricingSubRoutes: RouteConfig[] = [
  {
    path: "",
    name: constants.routes.TICKET_TYPES,
    component: () => import(/* webpackChunkName: "pricing" */ "@/views/pricing/PricingTt.vue"),
    meta: {
      breadcrumb: [
        { i18n: "Link.Home", url: "/" },
        { i18n: "Link.Pricing", url: "/pricing" },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRICING,
      browserTitle: constants.titles.TICKET_TYPES,
      subTabId: 0,
    },
  },
  {
    path: "pricing-adjustment",
    name: constants.routes.PRICING_ADJUSTMENT,
    component: () => import(/* webpackChunkName: "pricing" */ "@/views/pricing/PricingAdjustment.vue"),
    meta: {
      breadcrumb: [
        { i18n: "Link.Home", url: "/" },
        { i18n: "Link.PricingAdjustment", url: "/pricing/pricing-adjustment" },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRICING,
      browserTitle: constants.titles.PRICING_ADJUSTMENT,
      subTabId: 1,
    },
  },
  {
    path: "pricing-adjustment-list",
    name: constants.routes.PRICING_ADJUSTMENT_LIST,
    component: () => import(/* webpackChunkName: "pricing" */ "@/views/pricing/PricingAdjustmentList.vue"),
    meta: {
      breadcrumb: [
        { i18n: "Link.Home", url: "/" },
        { i18n: "Link.PricingAdjustment", url: "/pricing/pricing-adjustment-list" },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRICING,
      browserTitle: constants.titles.PRICING_ADJUSTMENT,
      subTabId: 1,
    },
  },
  {
    path: "pricing-schedule",
    name: constants.routes.PRICING_SCHEDULE,
    component: () => import(/* webpackChunkName: "pricing" */ "@/views/pricing/PricingSchedule.vue"),
    meta: {
      breadcrumb: [
        { i18n: "Link.Home", url: "/" },
        { i18n: "Link.PricingSchedule", url: "/pricing/pricing-schedule" },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRICING,
      browserTitle: constants.titles.PRICING_SCHEDULE,
      subTabId: 2,
    },
  },
];

const privateRoutes: RouteConfig[] = [
  /* COMPANY */
  {
    path: "",
    name: constants.routes.BASE,
    redirect: { name: constants.routes.ALL_BOOKINGS },
  },
  {
    path: "profile",
    name: constants.routes.PROFILE,
    component: () => import(/* webpackChunkName: "main" */ "@/views/users/UserProfile.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { i18n: "Link.Home", url: "/" },
        { i18n: "Link.Profile", url: "/profile" },
      ],
      title: constants.titles.SETTINGS,
    },
  },
  {
    path: "company",
    name: constants.routes.COMPANY,
    component: () => import(/* webpackChunkName: "company" */ "@/views/company/Company.vue"),
    meta: {
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.COMPANY,
      role: constants.roles.RESELLER_PORTAL_SUPER_ADMIN,
    },
  },
  {
    path: "bookings",
    name: constants.routes.BOOKINGS,
    component: () => import(/* webpackChunkName: "bookings" */ "@/views/bookings/BookingList.vue"),
    meta: {
      feature: constants.features.ROUTING_BOOKING_MAIN,
      title: constants.titles.BOOKINGS,
      role: [
        constants.roles.RESELLER_PORTAL_SUPER_ADMIN,
        constants.roles.RESELLER_PORTAL_LOCATION_SUPER_ADMIN,
        constants.roles.RESELLER_PORTAL_LOCATION_ADMIN,
        constants.roles.RESELLER_PORTAL_MANAGER,
        constants.roles.RESELLER_PORTAL_AGENT,
      ],
    },
  },
  {
    path: "add-bookings",
    name: constants.routes.BOOKINGS_ADD,
    component: () => import(/* webpackChunkName: "bookings" */ "@/views/bookings/BookingsAdd.vue"),
    meta: {
      feature: constants.features.ROUTING_BOOKING_MAIN,
      title: constants.titles.BOOKINGS_ADD,
      role: [
        constants.roles.RESELLER_PORTAL_SUPER_ADMIN,
        constants.roles.RESELLER_PORTAL_LOCATION_SUPER_ADMIN,
        constants.roles.RESELLER_PORTAL_LOCATION_ADMIN,
        constants.roles.RESELLER_PORTAL_MANAGER,
        constants.roles.RESELLER_PORTAL_AGENT,
      ],
    },
  },
  {
    path: "bookings/details/:id",
    name: constants.routes.BOOKING_DETAILS,
    component: () => import(/* webpackChunkName: "bookings" */ "@/views/bookings/BookingDetails.vue"),
    meta: {
      feature: constants.features.ROUTING_BOOKING_DETAILS,
      title: constants.titles.BOOKING_DETAILS,
      role: [
        constants.roles.RESELLER_PORTAL_SUPER_ADMIN,
        constants.roles.RESELLER_PORTAL_LOCATION_SUPER_ADMIN,
        constants.roles.RESELLER_PORTAL_LOCATION_ADMIN,
        constants.roles.RESELLER_PORTAL_MANAGER,
        constants.roles.RESELLER_PORTAL_AGENT,
      ],
    },
  },
  // {
  //   path: "pricing",
  //   component: () => import(/* webpackChunkName: "company" */ "@/views/pricing/Pricing.vue"),
  //   meta: {
  //     feature: constants.features.ROUTING_COMPANY_MAIN,
  //     title: constants.titles.COMPANY,
  //     role: constants.roles.RESELLER_PORTAL_SUPER_ADMIN,
  //   },
  // },
  {
    path: "pricing",
    name: constants.routes.PRICING_LISTING,
    component: () => import(/* webpackChunkName: "inventory" */ "@/views/pricing/PricingListing.vue"),
    meta: {
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.INVENTORY,
    },
    children: pricingSubRoutes,
  },
  {
    path: "reports/all-bookings",
    name: constants.routes.ALL_BOOKINGS,
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/AllBookings.vue"),
    meta: {
      feature: constants.features.ROUTING_REPORTS,
      title: constants.titles.AllBookings,
      role: [constants.roles.RESELLER_PORTAL_REPORTS],
    },
  },
  // {
  //   path: "reports/arrivals",
  //   component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/ArrivalsReport.vue"),
  //   meta: {
  //     feature: constants.features.ROUTING_REPORTS,
  //     title: constants.titles.ArrivalsReport,
  //   },
  // },
  {
    path: "users",
    name: constants.routes.USERS,
    component: () => import(/* webpackChunkName: "company" */ "@/views/users/UserManagement.vue"),
    meta: {
      feature: constants.features.ROUTING_USER_MAIN,
      title: constants.titles.USERS,
      role: [constants.roles.RESELLER_PORTAL_SUPER_ADMIN, constants.roles.RESELLER_PORTAL_LOCATION_SUPER_ADMIN],
    },
  },
  {
    path: "users/:id",
    name: constants.routes.USER_DETAILS,
    component: () => import(/* webpackChunkName: "users" */ "@/views/users/UserDetails.vue"),
    meta: {
      breadcrumb: [
        { i18n: "Link.Home", url: "/" },
        { i18n: "Link.Users", url: "/users" },
        { i18n: "Link.Details", url: "/users/:id", param: true },
      ],
      feature: constants.features.ROUTING_USER_MAIN,
      role: [constants.roles.RESELLER_PORTAL_SUPER_ADMIN, constants.roles.RESELLER_PORTAL_LOCATION_SUPER_ADMIN],
      title: constants.titles.USER,
    },
    props: true,
  },
  {
    path: "users/view/:id",
    name: constants.routes.USER_DETAILS_VIEW,
    component: () => import(/* webpackChunkName: "users" */ "@/views/users/UserDetailView.vue"),
    meta: {
      breadcrumb: [
        { i18n: "Link.Home", url: "/" },
        { i18n: "Link.Users", url: "/users" },
        { i18n: "Link.ViewDetails", url: "/users/view/:id", param: true },
      ],
      feature: constants.features.ROUTING_USER_MAIN,
      role: [constants.roles.RESELLER_PORTAL_SUPER_ADMIN, constants.roles.RESELLER_PORTAL_LOCATION_SUPER_ADMIN],
      title: constants.titles.USER,
    },
    props: true,
  },
  {
    path: "user/add",
    name: constants.routes.USER_MANAGEMENT_ADD,
    component: () => import(/* webpackChunkName: "company" */ "@/views/users/UserManagementAdd.vue"),
    meta: {
      feature: constants.features.ROUTING_USER_MAIN,
      title: constants.titles.USER,
      role: [constants.roles.RESELLER_PORTAL_SUPER_ADMIN, constants.roles.RESELLER_PORTAL_LOCATION_SUPER_ADMIN],
    },
  },
  {
    path: "home",
    name: constants.routes.HOME,
    redirect: { name: constants.routes.COMPANY },
  },
];
// =============================================================================
// PUBLIC(auth not required) ROUTES FOR FullPage LAYOUT
// =============================================================================

const publicRoutesWIP: RouteConfig[] = [];

const publicRoutes: RouteConfig[] = [
  {
    path: "login/",
    name: constants.routes.LOGIN,
    component: () => import(/* webpackChunkName: "full" */ "@/views/public/Login.vue"),
  },
  {
    path: "not-found",
    name: constants.routes.NOT_FOUND,
    component: () => import(/* webpackChunkName: "full" */ "@/views/public/ErrorNotFound.vue"),
  },
  {
    path: "forgot-password",
    name: constants.routes.FORGOT_PASSWORD,
    component: () => import(/* webpackChunkName: "forgot-password" */ "@/views/public/ForgotPassword.vue"),
  },
  {
    path: "reset-password",
    name: constants.routes.RESET_PASSWORD,
    component: () => import(/* webpackChunkName: "reset-password" */ "@/views/public/ResetPassword.vue"),
  },
  {
    path: "military",
    name: constants.routes.MILITARY,
    component: () => import(/* webpackChunkName: "main" */ "@/views/main/Military.vue"),
    meta: {
      requiresAuth: true,
      title: constants.titles.MILITARY,
    },
  },
];

if (!config.IsProductionEnv) {
  publicRoutes.push(...publicRoutesWIP);
}
// =============================================================================
// TOP LEVEL LAYOUT ROUTES
// =============================================================================

const layoutRoutes: RouteConfig[] = [
  {
    path: "",
    component: () => import(/* webpackChunkName: "main" */ "@/layouts/Main.vue"),
    meta: {
      requiresAuth: true, // see router.ts
    },
    children: privateRoutes,
  },
  {
    path: "",
    component: () => import(/* webpackChunkName: "full" */ "@/layouts/FullPage.vue"),
    children: publicRoutes,
  },
];

// =============================================================================
// ALL ROUTES
// =============================================================================

export const routes: RouteConfig[] = [
  ...layoutRoutes,
  {
    path: "*",
    redirect: { name: constants.routes.NOT_FOUND }, // see in publicRoutes
  },
];
