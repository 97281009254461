import axios, { AxiosInstance } from "axios";
import { from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { config } from "@/utils/config";
import { intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { AvailabilityListRS } from "./AvailabilityModels";
import { AvailabilityRSErrHandlers, BookingAPIAxiosErrorInterceptor } from "./ErrHandlers";

const xsrfCookieName = "XSRF-TOKEN";
const availabilityApi = "/availability-list";
const specificAvailabilityApi = "/availability-specific";

export class AvailabilityClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.BookingApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(BookingAPIAxiosErrorInterceptor));
  }

  public availabilityList(params: any, errs?: AvailabilityRSErrHandlers): Observable<any> {
    return from(
      this.httpClient.get<any>(`${availabilityApi}` + "/" + params.supplierId + "/" + params.productId + "/" + params.rateId, {
        params: {
          start: params.startDate,
          end: params.endDate,
        },
      }),
    ).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onAvailabilityNotFound && e.status === 404) {
            errs.onAvailabilityNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public availabilityListBySupplierProduct(params: any, errs?: AvailabilityRSErrHandlers): Observable<any> {
    return from(
      this.httpClient.get<any>(`${availabilityApi}` + "/" + params.supplierId + "/" + params.productId, {
        params: {
          start: params.startDate,
          end: params.endDate,
        },
      }),
    ).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onAvailabilityNotFound && e.status === 404) {
            errs.onAvailabilityNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getAvailabilityRS(params: any): Promise<any> {
    const availability = await this.availabilityList(params).toPromise();
    const av = availability?.availabilities?.byRate;

    if (av && Object.keys(av)?.length) {
      const availVals = av[params.rateId]?.availability;
      if (availVals?.length) {
        return this.mapAvailabilityRS(availVals);
      } else {
        return availability;
      }
    }
  }

  private mapAvailabilityRS(availabilityRS: AvailabilityListRS) {
    return availabilityRS;
  }

  /* Specific Availability */

  private availabilitySpecific(params: any, errs?: AvailabilityRSErrHandlers): Observable<any> {
    return from(
      this.httpClient.get<any>(`${specificAvailabilityApi}` + "/" + params.supplierId + "/" + params.productId + "/" + params.rateId, {
        params: {
          at: params.at,
          qty: params.qty,
        },
      }),
    ).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onAvailabilityNotFound && e.status === 404) {
            errs.onAvailabilityNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getSpecificAvailabilityRS(params: any): Promise<any> {
    const availability = await this.availabilitySpecific(params).toPromise();

    if (availability && Object.keys(availability)?.length) {
      return [availability];
    } else {
      return [];
    }
  }
}

const availabilityClient = new AvailabilityClient();

export default availabilityClient;
