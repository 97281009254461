
import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  mounted() {
    const appLoading: any = document.getElementById("loading-bg");
    if (appLoading) appLoading.style.display = "none";
  }
}
