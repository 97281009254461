import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "@/router/routes";
import { constants, Role } from "@/utils/constants";
import { IsRole, profileModule } from "@/store/modules/moduleProfile";
import { httpModule } from "@/store/modules/moduleHttp";
import { appModule } from "@/store/modules/moduleApp";
import { codes } from "@/utils/codeConstants";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthRequired = to.matched.some((r) => r.meta?.requiresAuth);

  try {
    if (!isAuthRequired) {
      next();
    } else {
      // each route has its own access policy
      const roleRequired = to.meta?.role as Role | undefined;

      // if (appModule.Features.length === 0) {
      //   await httpModule.getPublicFeatures();
      // }

      if (!profileModule.Profile) {
        await httpModule.getProfile();
      }
      if (roleRequired && !profileModule.IsSuperAdmin && !IsRole(profileModule.Profile, roleRequired)) {
        next({ name: constants.routes.BOOKINGS_ADD }); // rerouting to booking add instead of home
        appModule.addMessageError(codes.PROFILE_ERROR_UNAUTHORIZED);
        // todo send message to backend
        return;
      }

      appModule.setPreviousRoute(from);
      next();
    }
  } catch (e: any) {
    //next(false);
    next({ name: constants.routes.LOGIN });
    e.where = "router beforeEach hook";
    throw e;
  }
});

router.afterEach((to) => {
  Vue.nextTick().then(() => {
    // set page title
    document.title = `${to?.meta?.browserTitle || to?.meta?.title || "Redeam"} - Reseller Portal`;
  });
});

export default router;
