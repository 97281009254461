import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import Vue from "vue";
import { sessionStorageSrv } from "@/service/SessionStorageSrv";

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-user",
  namespaced: true,
})
class ModuleUser extends VuexModule {
  /* Data */
  private userUpdated = false;
  private loading = false;
  private users: any[] = [];
  private bulkAction = "";
  private selectedUsers: any[] = [];
  private userToEdit = -1;
  private userIndexToDelete = -1;
  private userToDelete = "";
  private userManagementPage = 1;
  private selectedUserStatus = 0;
  private userManagementSelectedRole: Array<string> = [];
  private userManagementSelectedPOS: Array<string> = [];

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get UserUpdated(): boolean {
    return this.userUpdated;
  }

  get Users(): any[] {
    return this.users;
  }

  get UserToEdit(): any {
    if (this.userToEdit !== -1) {
      return this.users[this.userToEdit];
    }
    return null;
  }

  get UserIndexToDelete(): any {
    if (this.userIndexToDelete !== -1) {
      return this.users[this.userIndexToDelete];
    }
    return null;
  }

  get UserToDelete(): any {
    if (this.userToDelete !== "") {
      return this.users.find((user) => user.id === this.userToDelete);
    }
    return null;
  }

  get SelectedUsers(): any[] {
    return this.selectedUsers;
  }

  get BulkAction(): string {
    return this.bulkAction;
  }

  get ExistingGroups(): any[] {
    return this.users[this.userToEdit] ? this.users[this.userToEdit].groups : [];
  }

  get UserManagementPage() {
    return this.userManagementPage;
  }

  get UserManagementSelectedRole() {
    return this.userManagementSelectedRole;
  }

  get UserManagementSelectedPOS() {
    return this.userManagementSelectedPOS;
  }

  get UserManagementSelectStatus() {
    return this.selectedUserStatus;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setUserUpdated(state: boolean) {
    this.userUpdated = state;
  }

  @Mutation
  chooseUserToEdit(i: number) {
    this.userToEdit = i;
  }

  @Mutation
  chooseUserIndexToDelete(i: number) {
    this.userIndexToDelete = i;
  }

  @Mutation
  chooseUserToDelete(id: string) {
    this.userToDelete = id;
  }

  @Mutation
  setUsers(users: any[]) {
    if (users) {
      this.users = users.map((v, i) => ({ i, ...v }));
    } else {
      this.users = [];
    }
  }

  @Mutation
  setSelectedUsers(users: any[]) {
    this.selectedUsers = users;
  }

  @Mutation
  setBulkAction(action: string) {
    this.bulkAction = action;
  }

  @Mutation
  addUser(user: any) {
    const arr = this.users;
    arr.forEach((v) => ++v.i);
    arr.unshift({
      ...user,
      i: 0,
    });
  }

  @Mutation
  editUser(v: any) {
    Vue.set(this.users, this.userToEdit, { ...v, i: this.userToEdit });
  }

  @Mutation
  dropUser() {
    const arr = this.users;
    arr.splice(this.userToEdit, 1);
    for (let j = this.userToEdit; j < arr?.length; ++j) {
      arr[j].i = j;
    }
    this.userToEdit = -1;
  }

  @Mutation
  updateUserStatus(status: number) {
    const arr = this.users;
    if (status === 1) {
      delete arr[this.userToEdit].deactivated_at;
      // arr[this.userToEdit].deactivated_at = null;
    } else if (status === 2) {
      arr[this.userToEdit].deactivated_at = Date.now();
    }

    this.userToEdit = -1;
  }

  @Mutation
  dropBulkUser() {
    const arr = this.users;
    arr.splice(this.userIndexToDelete, 1);
    for (let j = this.userIndexToDelete; j < arr?.length; ++j) {
      arr[j].i = j;
    }
    this.userIndexToDelete = -1;
  }

  @Mutation
  setUserManagementPage(page: number) {
    this.userManagementPage = page;
  }

  @Mutation
  setUserManagementSelectedStatus(status: number) {
    this.selectedUserStatus = status;
  }

  @Mutation
  setUserManagementSelectedRole(roles: Array<string>) {
    this.userManagementSelectedRole = roles;
  }

  @Mutation
  setUserManagementSelectedPOS(pos: Array<string>) {
    this.userManagementSelectedPOS = pos;
  }

  /* Actions */
  @Action
  chooseSelectedUserToUpdate(id: string) {
    const i = this.Users.findIndex((user: any) => user.id === id);
    this.chooseUserToEdit(i);
  }

  @Action
  chooseUserToUpdate(i: number) {
    this.chooseUserToEdit(i);
  }

  @Action
  chooseUserToRemove(id: string) {
    this.chooseUserToDelete(id);
  }

  @Action
  async chooseUserIndexToRemove(id: string) {
    const i = this.Users.findIndex((user: any) => user.id === id);
    this.chooseUserIndexToDelete(i);
  }

  @Action
  async chooseUserIndexToEdit(id: string) {
    const i = this.Users.findIndex((user: any) => user.id === id);
    this.chooseUserToEdit(i);
  }

  @Action
  updateUserManagementPage(page: number) {
    sessionStorageSrv.usrMngmtPage = page;
    this.setUserManagementPage(page);
  }

  @Action
  updateUserManagementSelectedRole(roles: Array<string>) {
    sessionStorageSrv.usrMngmtSelectedRole = roles;
    this.setUserManagementSelectedRole(roles);
  }

  @Action
  updateUserManagementSelectedPOS(pos: Array<string>) {
    sessionStorageSrv.usrMngmtSelectedPOS = pos;
    this.setUserManagementSelectedPOS(pos);
  }

  @Action
  checkUserManagementPage() {
    this.setUserManagementPage(sessionStorageSrv.usrMngmtPage ?? 1);
  }

  @Action
  checkUserManagementSelectedRole() {
    this.setUserManagementSelectedRole(sessionStorageSrv.usrMngmtSelectedRole ?? []);
  }

  @Action
  checkUserManagementSelectedPOS() {
    this.setUserManagementSelectedPOS(sessionStorageSrv.usrMngmtSelectedPOS ?? []);
  }

  @Action
  resetUserManagement() {
    sessionStorageSrv.removeUsrMngmtSelectedRole();
    sessionStorageSrv.removeUsrMngmtSelectedPOS();
    sessionStorageSrv.removeUsrMngmtPage();
    this.setUserManagementSelectedRole([]);
    this.setUserManagementSelectedPOS([]);
    this.setUserManagementPage(1);
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.resetUserManagement();
    this.setUsers([]);
    this.setBulkAction("");
    this.chooseUserToEdit(-1);
    this.chooseUserIndexToDelete(-1);
    this.chooseUserToDelete("");
    this.setUserManagementPage(1);
    this.setUserManagementSelectedStatus(0);
    this.setUserManagementSelectedRole([] as Array<string>);
    this.setUserManagementSelectedPOS([] as Array<string>);
  }
}

export const userModule = getModule(ModuleUser, store);
